import { useNavigate } from "react-router-dom";
import Main from "../../components/main";
import Content from "../../components/content";
import styles from "./index.module.scss";
import { useSetState } from "ahooks";
import api from "../../services/api";
import toast from "../../components/toast";
import { http } from "../../services/fetcher";
import md5 from "js-md5";
import { classNames } from "../../tool";
import { setLocalStore } from "../../tool/localStore";

export default function Password() {
  const [state, setState] = useSetState({
    oldPwd: "",
    newPwd: "",
    confirmPwd: "",
    oldPwdStatus: true,
    newPwdStatus: true,
    confirmPwdStatus: true,
  });
  const {
    oldPwd,
    newPwd,
    confirmPwd,
    oldPwdStatus,
    newPwdStatus,
    confirmPwdStatus,
  } = state;
  const navigate = useNavigate();
  // 修改密码操作
  const handlChangePassword = async () => {
    if (oldPwd && newPwd && confirmPwd) {
      if (newPwd === oldPwd) {
        toast({ msg: "您的新密码与原密码一致" });
        return;
      }
      if (newPwd !== confirmPwd) {
        toast({ msg: "两次输入密码不一致" });
        return;
      }
      const res = await http(api.password, {
        oldPassword: md5(oldPwd),
        password: md5(newPwd),
      });
      if (res.code === "C2") {
        toast({
          msg: "修改成功",
          callback: () => {
            navigate("/login");
            setLocalStore("token", "");
            localStorage.removeItem("login");
            localStorage.removeItem("handicap");
            localStorage.removeItem("select");
          },
        });
        setState({
          newPwd: "",
          oldPwd: "",
          confirmPwd: "",
        });
      } else {
        toast({ msg: res.message });
      }
    } else {
      toast({ msg: "密码不可以空白" });
    }
  };
  return (
    <Main>
      <Content>
        <div className={styles.contentItem}>
          <div className={styles.itemTitle}>修改密码</div>
          <div className={styles.accountItem}>
            <div className={styles.label}>原密码</div>
            <div className={styles.value}>
              <input
                type={"password"}
                // placeholder="请再次输入新密码"
                value={oldPwd}
                maxLength={15}
                onChange={(e) => {
                  const value = e.target.value;
                  setState({
                    oldPwd: value,
                  });
                }}
              />
            </div>
          </div>
          <div className={styles.accountItem}>
            <div className={styles.label}>新密码</div>
            <div className={styles.value}>
              <input
                type={"password"}
                value={newPwd}
                maxLength={15}
                onChange={(e) => {
                  const value = e.target.value;
                  setState({
                    newPwd: value,
                  });
                }}
              />
            </div>
          </div>
          <div className={styles.accountItem}>
            <div className={styles.label}>确认密码</div>
            <div className={styles.value}>
              <input
                type={"password"}
                value={confirmPwd}
                maxLength={15}
                onChange={(e) => {
                  const value = e.target.value;
                  setState({
                    confirmPwd: value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.btn} onClick={handlChangePassword}>
          确认修改
        </div>
      </Content>
    </Main>
  );
}
