import React, { useEffect, useState } from "react";
import initNECaptchaWithFallback from "./yidun";
import styles from "./index.module.scss";

const YidunCaptcha = (props) => {
  const { children, callback, onBefore, disabled, id, sysSetting } = props;
  const [captchaIns, setCaptchaIns] = useState(null);
  const [success, setSuccess] = useState(false);

  const handlClick = () => {
    if (captchaIns) {
      captchaIns.refresh();
    }
    if (disabled) {
      return;
    }
    if (onBefore) {
      if (!onBefore()) {
        return;
      }
    }
    // 验证通过之后不需要再次验证
    // if (success) {
    //   callback(true);
    //   return;
    // }
    captchaIns && captchaIns.verify();
  };

  useEffect(() => {
    if (sysSetting && sysSetting["wangyi.captchaId"]) {
      initNECaptchaWithFallback(
        {
          element: `#${id || ""}-yidun-captcha`,
          captchaId: sysSetting ? sysSetting["wangyi.captchaId"] : "",
          width: "320px",
          mode: "popup",
          apiVersion: 2,
          popupStyles: {
            position: "fixed",
            top: "20%",
          },
          onVerify: function (err, data) {
            if (err) return; // 当验证失败时，内部会自动refresh方法，无需手动再调用一次
            setSuccess(true);
            // 若成功拿到 validate，可向后端发送请求
            callback(data.validate);
          },
        },
        function onload(instance) {
          setCaptchaIns(instance);
        },
        function onerror(err) {
          console.warn(err);
        }
      );
    }
  }, [sysSetting, callback, id]);

  return (
    <div className={styles.container}>
      <p onClick={handlClick}></p>
      <div id={`${id || ""}-yidun-captcha`}></div>
      {children}
    </div>
  );
};

export default YidunCaptcha;
