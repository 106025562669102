import { useEffect } from "react";
import { useSetState } from "ahooks";
import { observer, inject } from "mobx-react";
import Main from "../../components/main";
import Content from "../../components/content";
import MyPagination from "../../components/pagination";
import styles from "./index.module.scss";
import { http } from "../../services/fetcher";
import api from "../../services/api";
import dayjs from "dayjs";
import CopyToClipboard from "react-copy-to-clipboard";
import Toast from "../../components/toast";
import { getUrlParamsToJson } from "../../tool";
function ReportDetail({ store }) {
  const urlParams = getUrlParamsToJson();
  const tableColumns = [
    {
      title: "注单号/时间",
      dataIndex: "ctime",
      render: (val, item) => {
        return (
          <div className={styles.orderTime}>
            <div className={styles.order}>
              <div className={styles.orderText}>{item.rowId}</div>
              <CopyToClipboard
                text={item.rowId}
                onCopy={() => {
                  Toast({ msg: "复制成功" });
                }}
              >
                <div className={styles.copyBtn}>复制</div>
              </CopyToClipboard>
            </div>

            <span className={styles.time}>
              {dayjs(val).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        );
      },
    },
    {
      title: "游戏",
      dataIndex: "betNum",
      render: (val) => {
        return "新澳门六合彩";
      },
    },
    {
      title: "盘口",
      dataIndex: "betHall",
      render: (val) => {
        return `${val}盘`;
      },
    },
    {
      title: "下注类型",
      dataIndex: "playCode3Name",
      render: (val, record) => {
        return (
          <div className={styles.betType}>
            <div className={styles.issue}>第{record.issueId}期</div>
            <div className={styles.type}>{`${
              val ? val : record.playCode2Name
            }`}</div>
          </div>
        );
      },
    },
    {
      title: "注单明细",
      dataIndex: "betNum",
      render: (val, record) => {
        return (
          <div className={styles.betContainer}>
            <span className={styles.betList}>
              {record.betContentFromat &&
                record.betContentFromat.map((i, idx) => {
                  return (
                    <span key={i}>
                      {record.playCode2Name === "尾数" &&
                      record.playCode3Name === "尾数"
                        ? `${i}尾`
                        : i}
                      {idx < record.betContentFromat.length - 1 ? "," : ""}
                    </span>
                  );
                })}
            </span>
            @<span className={styles.betOdds}>{`${record.betOdds}`}</span>
          </div>
        );
      },
    },
    {
      title: "下注金额",
      dataIndex: "betAmount",
    },
    {
      title: "返水",
      dataIndex: "betTax",
    },
    {
      title: "返水后结果",
      dataIndex: "finalAmount",
      render: (val) => {
        return <span className={Number(val) < 0 ? styles.red : ""}>{val}</span>;
      },
    },
  ];
  const [state, setState] = useSetState({
    tableData: [],
    current: 1,
    total: 0,
    formTableData: [],
    refreshCount: 0,
    urlStart: Number(urlParams.start),
  });
  const { tableData, current, total, formTableData, refreshCount, urlStart } =
    state;
  const getData = () => {
    const getOrder = async () => {
      const realParams = {
        state: 1,
        page: {
          current,
          size: 20,
        },
      };
      if (urlStart) {
        realParams.startTime = dayjs(Number(urlParams.start))
          .startOf("day")
          .valueOf();
        realParams.endTime = dayjs(Number(urlParams.start))
          .endOf("day")
          .valueOf();
      }
      const res = await http(api.historyOrder, realParams);
      if (res.code === "C2") {
        const data = res.result.records;
        setState({
          tableData: data,
          total: res.result.total,
          refreshCount: refreshCount + 1,
        });
      }
    };
    getOrder();
  };
  const { playInfo } = store;
  const rules =
    (JSON.parse(playInfo.playInfo || "{}").LHC || {}).playInfo || [];
  const getPlayItem = (item) => {
    const { playCode2, playCode3, betContent } = item;
    let plays = [];
    rules.forEach((i) => {
      if (i.code === playCode2) {
        if (i.plays) {
          plays = i.plays;
        } else {
          const secondPlay =
            i.children.find((it) => it.code === playCode3) || {};
          plays = secondPlay.plays || [];
        }
      }
    });
    const betContentFromat = plays.length
      ? betContent.map((it) => {
          const item = plays.find((i) => i.code === it);
          return item ? item.name : it;
        })
      : betContent;
    return betContentFromat;
  };
  useEffect(() => {
    if (rules.length) {
      const newData = (tableData || []).map((item) => {
        return {
          ...item,
          betContentFromat: getPlayItem(item),
        };
      });
      setState({
        formTableData: newData,
      });
    }
  }, [rules.length, tableData.length, refreshCount]);

  useEffect(() => {
    getData();
  }, [current]);

  return (
    <Main>
      <Content className={styles.content}>
        <div className={styles.innerContent}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.thead}>
                {tableColumns.map((item, index) => {
                  return (
                    <th
                      className={styles.th}
                      key={index}
                      style={{ width: item.width }}
                    >
                      {item.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {formTableData.map((item, index) => {
                return (
                  <tr className={styles.tr} key={index}>
                    {tableColumns.map((it, idx) => {
                      return (
                        <td
                          className={styles.td}
                          key={idx}
                          style={{ width: it.width }}
                        >
                          {it.render
                            ? it.render(item[it.dataIndex], item)
                            : item[it.dataIndex]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {formTableData.length ? (
            <div className={styles.pagination}>
              <MyPagination
                current={current}
                size={20}
                total={total}
                onChange={(val) => setState({ current: val })}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Content>
    </Main>
  );
}

export default inject("store")(observer(ReportDetail));
