import { useNavigate } from "react-router-dom";
import CryptoJs from "crypto-js";
import { Modal } from "antd";
import Main from "../../components/main";
import styles from "./index.module.scss";
import { useState, useRef, useEffect } from "react";
import api from "../../services/api";
import toast from "../../components/toast";
import { http } from "../../services/fetcher";
import { observer, inject } from "mobx-react";
import { classNames } from "../../tool";
import { setLocalStore } from "../../tool/localStore";
import md5 from "js-md5";
import YidunCaptcha from "./YidunCaptcha";
import Rules from "./rule";

function Login({ store }) {
  const { dicInfo } = store;
  const [accountNo, setAccountNo] = useState("");
  const [password, setPassword] = useState("");
  const [accountPwdStatus, setAccountPwdStatus] = useState(true);
  const [ruleVisible, setRuleVisible] = useState(false);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [first, setFirst] = useState(false);
  const [newPwd, setNewPwd] = useState("");
  const [confirmNewPwd, setConfirmNewPwd] = useState("");
  const [bindIp, setBindIp] = useState("");
  const navigate = useNavigate();

  const handleLoginClick = async (validate) => {
    if (password && accountNo) {
      const params = {
        type: 0,
      };
      params.accountNo = accountNo;
      params.password = md5(password);

      if (validate) {
        params.validateCode = validate;
      }
      const res = await http(api.login, params);
      if (res.code === "C2") {
        localStorage.setItem("login", "login");
        localStorage.setItem(
          "fff",
          CryptoJs.AES.encrypt(password, "account").toString()
        );
        setLocalStore("acc", JSON.stringify(res.result));
        store.setUserInfo(res.result);
        setRuleVisible(true);
        if (res.result.first) {
          setFirst(true);
        } else {
          setFirst(false);
        }
      } else {
        toast({ msg: res.message });
      }
    } else {
      toast({ msg: "请正确输入账号或密码" });
    }
  };

  const renderLoginBtn = (isNeedClick) => {
    return (
      <div
        className={classNames(
          styles.btn,
          accountNo && password ? "" : styles.disabled
        )}
        onClick={() => {
          if (isNeedClick) {
            handleLoginClick("");
          }
        }}
      ></div>
    );
  };
  const handleChangePwd = async () => {
    const pwdBytes = CryptoJs.AES.decrypt(
      localStorage.getItem("fff") ? localStorage.getItem("fff") : "",
      "account"
    );

    if (newPwd && confirmNewPwd) {
      if (newPwd !== confirmNewPwd) {
        toast({ msg: "两次输入密码不一致" });
        return;
      }
      if (password === newPwd) {
        toast({ msg: "新旧密码不能一样" });
        return;
      }
      // let errText = "";
      // const ipReg =
      //   /((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)/;
      // const ipArr = bindIp.split(",");
      // ipArr.forEach((i) => {
      //   if (i && !ipReg.test(i)) {
      //     errText = "请输入正确的IP";
      //   }
      // });
      // if (errText) {
      //   toast({ msg: errText });
      //   return;
      // }
      // const resIp = await http(api.bindAccessIp, {
      //   accountNo: accountNo,
      //   accessIps: bindIp,
      // });
      // if (resIp.code === "C2") {
      const res = await http(api.password, {
        oldPassword: md5(password),
        password: md5(newPwd),
      });
      if (res.code === "C2") {
        setPwdVisible(false);
        toast({ msg: "修改成功" });
      } else {
        toast({ msg: res.message });
        return;
      }
      // } else {
      //   toast({ msg: resIp.message });
      // }
    }
  };
  return (
    <Main className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>用户登录</div>
        <div className={styles.item}>
          <div className={styles.input}>
            <img
              className={styles.icon}
              src={require("../../assets/imgs/web/username.png")}
            />
            <input
              type={"text"}
              placeholder="用户名"
              value={accountNo}
              maxLength={16}
              onChange={(e) => {
                setAccountNo(e.target.value);
              }}
            />
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.input}>
            <img
              className={styles.icon}
              src={require("../../assets/imgs/web/password.png")}
            />
            <input
              type={accountPwdStatus ? "password" : "text"}
              placeholder={`密码`}
              value={password}
              maxLength={15}
              onChange={(e) => {
                const value = e.target.value.replace(/\s/g, "");
                setPassword(e.target.value);
              }}
            />
            <img
              className={styles.iconEye}
              src={require(`../../assets/imgs/eye${
                accountPwdStatus ? "Close" : "Open"
              }.png`)}
              alt=""
              onClick={() => {
                setAccountPwdStatus(!accountPwdStatus);
              }}
            />
          </div>
        </div>

        {Number(dicInfo[`wangyi.enable`]) ? (
          <YidunCaptcha
            callback={handleLoginClick}
            disabled={!accountNo || !password}
            id={"login"}
            sysSetting={store.dicInfo}
          >
            {renderLoginBtn()}
          </YidunCaptcha>
        ) : (
          renderLoginBtn(true)
        )}
      </div>
      <Modal
        width={1140}
        className={styles.ruleAction}
        open={ruleVisible}
        closeIcon={null}
        maskClosable
        onClose={() => {
          setRuleVisible(false);
        }}
        footer={null}
      >
        <div className={styles.ruleModal}>
          <div className={styles.title}>用户协议与规则</div>
          <div className={styles.ruleContent}>
            <div className={styles.ruleList}>
              {Rules.map((item, index) => {
                return (
                  <div className={styles.ruleItem} key={index}>
                    {item.text}
                  </div>
                );
              })}
              {/* <div className={styles.name}>六合彩官方</div> */}
            </div>
          </div>
          <div className={styles.ruleFooter}>
            <div
              className={classNames(styles.btn, styles.cancel)}
              onClick={() => {
                setRuleVisible(false);
                setLocalStore("token", "");
                localStorage.removeItem("login");
                localStorage.removeItem("handicap");
                localStorage.removeItem("select");
              }}
            >
              不同意
            </div>
            <div
              className={classNames(styles.btn, styles.confirm)}
              onClick={() => {
                setRuleVisible(false);
                if (first) {
                  setPwdVisible(true);
                } else {
                  navigate("/play");
                }
              }}
            >
              同意
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={pwdVisible}
        onClose={() => setPwdVisible(false)}
        closeIcon={null}
        maskClosable
        footer={null}
      >
        <div className={styles.pwdModal}>
          <div className={styles.pwdTitle}>修改登录密码</div>
          <div className={styles.pwdTip}>首次登录需修改登录密码</div>
          <div className={styles.pwdItem}>
            <div className={styles.label}>新密码</div>
            <input
              type="password"
              placeholder="请输入新密码"
              value={newPwd}
              maxLength={15}
              onChange={(e) => {
                const value = e.target.value.replace(/\s/g, "");
                setNewPwd(value);
              }}
            />
          </div>
          <div className={styles.pwdItem}>
            <div className={styles.label}>确认密码</div>
            <input
              type="password"
              placeholder="请再次确认密码"
              value={confirmNewPwd}
              maxLength={15}
              onChange={(e) => {
                const value = e.target.value.replace(/\s/g, "");
                setConfirmNewPwd(value);
              }}
            />
          </div>

          <div className={styles.btn} onClick={handleChangePwd}>
            确认修改
          </div>
        </div>
      </Modal>
    </Main>
  );
}
export default inject("store")(observer(Login));
