const Rules = [
  {
    text: "1.为避免出现争议，请您务必在下注之后查看「下注状况」",
  },
  { text: "2.任何投诉必须在开奖之前，后台将不接受任何开奖之后的投诉。" },
  {
    text: "3.公布赔率时出现的任何打字错误或非故意人为失误，所有（相关）注单一律不算。",
  },
  {
    text: "4.会员版面公布的为浮动赔率，与下注实际赔率会有差异，属正常浮动。下注成功后请确认注单赔率及金额！",
  },
  { text: "5.开奖后接受的投注，一律视为无效。" },
  {
    text: "6.若本后台发现客户以不正当的手法投注或投注注单不正常，后台将有权「取消」相应之注单，客户不得有任何异议。",
  },
  {
    text: "7.如因软件或线路问题导致交易内容或其他与账号设定不符合的情形，请在开奖前立即与本后台联络反映问题，否则本后台将以资料库中的数据为准。",
  },
  {
    text: "8.如因服务供应商或线路问题导致赛果与官方赛果不符合的情形。本后台将竭力调查以确定赛果，除非后台所发布的赛果明显错误，否则本后台的决定将是最终决定。受影响之注单将重新结算，后台将以重新结算的数据为准，客户不得有任何异议。",
  },
  {
    text: "9.倘若发生遭黑客入侵破坏行为或不可抗拒之灾害致网站故障或资料损坏、数据丢失等情况，后台将以资料库数据为依据。",
  },
  {
    text: "10.各级管理人员及客户必须对本系统各项功能进行了解及熟悉，任何违反正常使用的操作，后台概不负责。",
  },
  { text: "11.请认真了解游戏规则。" },
  { text: "12.如果会员信用额度超额或者为负数引起的争议，一律以注单报表为准。" },
  {
    text: "13.客户有责任确保自己的账户及密码保密，如果因客户的账户、密码简单，或因泄露导致被盗用，造成的损失由客户本人承担；同时应立即通知本公司，并更改其个人详细资料。",
  },
  { text: "14.会员账号余额只做参考不做交收依据,一律以报表为准。" },
];

export default Rules;
