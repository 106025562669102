import Main from "../../components/main";
import { Select } from "antd";
import Content from "../../components/content";
import styles from "./index.module.scss";
import { DescList, Rules1, Rules2 } from "./constans";
import { classNames } from "../../tool";

function GameDesc() {
  return (
    <Main>
      <Content className={styles.content}>
        <div className={styles.descContainer}>
          <div className={styles.title}>
            游戏规则
            <Select
              className={styles.select}
              value={1}
              style={{ width: 120 }}
              onChange={(val) => {}}
              options={[{ label: "新澳门六合彩", value: 1 }]}
            />
          </div>
          <div className={styles.ruleItem}>
            <div className={styles.ruleTitle}>{Rules1.title}</div>
            {Rules1.text.map((item, index) => {
              return (
                <div className={styles.ruleText} key={index}>
                  {item}
                </div>
              );
            })}
          </div>
          <div className={styles.descTitle}>新澳门六合彩</div>
          {DescList.map((content, index) => {
            return (
              <div className={styles.contentItem} key={index}>
                <div className={styles.contentTitle}>※ {content.title}</div>
                {content.content.map((item, idx) => {
                  return (
                    <div className={styles.contentInner} key={index + idx}>
                      <div className={styles.label}>●{item.title}：</div>
                      <div className={styles.value}>
                        {Array.isArray(item.text)
                          ? item.text.map((i) => (
                              <div className={styles.text} key={i}>
                                {i}
                              </div>
                            ))
                          : item.text}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Content>
    </Main>
  );
}

export default GameDesc;
