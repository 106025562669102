import { useEffect, useState } from "react";
import styles from "./index.module.css";

export default function Modal(props) {
  const [status, setStatus] = useState("");
  useEffect(() => {
    if (props.visible) {
      setStatus("show");
    } else {
      if (status) {
        setStatus("hide");
        // setTimeout(() => {
        //   setStatus("hide")
        // }, 400)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  return status === "show" || status === "animate" ? (
    <div
      className={`${styles.modal} ${
        status === "animate" ? styles.animate : ""
      }`}
      onClick={() => {
        if (props.close) {
          props.close();
        }
      }}
    >
      <div
        className={styles.content}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
      </div>
    </div>
  ) : null;
}
