import { useEffect } from "react";
import { useSetState } from "ahooks";
import { useNavigate } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { DatePicker, Select } from "antd";
import Main from "../../components/main";
import Content from "../../components/content";
import Empty from "../../components/empty";
import styles from "./index.module.scss";
import { classNames, getTime2 } from "../../tool";
import { http } from "../../services/fetcher";
import api from "../../services/api";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
function Report({ store }) {
  const navigate = useNavigate();
  const dateList = [
    { label: "今天", start: 0, end: 0 },
    { label: "昨天", start: 1, end: 1 },
    { label: "本周", start: 6, end: 0 },
    { label: "上周", start: 14, end: 6 },
  ];
  const tableColumns = [
    {
      title: "日期",
      dataIndex: "cTime",
      render: (val) => {
        return (
          <span
            className={styles.date}
            onClick={() => {
              navigate(`/reportDetail?start=${val}&end=${val}`);
            }}
          >
            {dayjs(val).format("YYYY-MM-DD")}
          </span>
        );
      },
    },
    {
      title: "注单数",
      dataIndex: "betNum",
    },
    {
      title: "下注金额",
      dataIndex: "betAmount",
      render: (val) => {
        return val;
      },
    },

    {
      title: "结果",
      dataIndex: "gameAmount",
      render: (val) => {
        return <span className={Number(val) > 0 ? "" : styles.red}>{val}</span>;
      },
    },
    {
      title: "返水",
      dataIndex: "betTax",
      render: (val) => {
        return val;
      },
    },
    {
      title: "返水后结果",
      dataIndex: "finalAmount",
      render: (val, data) => {
        return (
          <span className={Number(val) >= 0 ? "" : styles.red}>{val}</span>
        );
      },
    },
  ];
  const [state, setState] = useSetState({
    dateIndex: 0,
    tableData: [],
    start: getTime2(0).start,
    end: getTime2(0).end,
    typeVisible: false,
    refreshCount: 0,
    total: {
      betNum: 0,
      betAmount: 0,
      gameAmount: 0,
      betTax: 0,
    },
  });
  const { dateIndex, tableData, total, start, end, refreshCount } = state;
  const getData = () => {
    let params = {
      startTime: "",
      endTime: "",
    };

    params = {
      startTime: start,
      endTime: end,
      state: 1,
    };

    const getSettelment = async () => {
      const res = await http(api.settlement, params);
      if (res.code === "C2") {
        const data = res.result;

        setState({
          tableData: data,
        });
      }
    };
    const getSettelmentTotal = async () => {
      const res = await http(api.settlementTotal, params);
      if (res.code === "C2") {
        const data = res.result;
        setState({
          total: data,
        });
      }
    };
    getSettelment();
    getSettelmentTotal();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const time = getTime2(dateIndex);
    setState({
      start: time.start,
      end: time.end,
    });
  }, [dateIndex, refreshCount]);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };
  return (
    <Main>
      <Content className={styles.content}>
        <div className={styles.topInfo}>
          <div className={styles.item}>
            <div className={styles.label}>请选择彩种</div>
            <Select
              className={styles.select}
              value={"1"}
              style={{ width: 150 }}
              onChange={(val) => {}}
              options={[
                {
                  label: "新澳门六合彩",
                  value: "1",
                },
              ]}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.label}>请选择日期</div>
            <DatePicker
              disabledDate={disabledDate}
              placeholder=""
              onChange={(val) => {
                setState({
                  start: dayjs(val).valueOf(),
                });
              }}
              locale={locale}
              value={start ? dayjs(start) : ""}
            />
            <span className={styles.divider}>-</span>
            <DatePicker
              disabledDate={disabledDate}
              placeholder=""
              onChange={(val) => {
                setState({
                  end: dayjs(val).valueOf(),
                });
              }}
              locale={locale}
              value={end ? dayjs(end) : ""}
            />
          </div>
          <div className={styles.timeSelect}>
            {dateList.map((item, index) => {
              return (
                <div
                  className={classNames(
                    styles.timeBtn,
                    dateIndex === index ? styles.active : ""
                  )}
                  key={index}
                  onClick={() => {
                    setState({
                      dateIndex: index,
                      refreshCount: refreshCount + 1,
                    });
                  }}
                >
                  {item.label}
                </div>
              );
            })}
            <div
              className={classNames(styles.timeBtn, styles.searchBtn)}
              onClick={getData}
            >
              查看
            </div>
          </div>
        </div>

        <div className={styles.innerContent}>
          <div className={styles.table}>
            <div className={styles.thead}>
              {tableColumns.map((item, index) => {
                return (
                  <div
                    className={styles.th}
                    key={index}
                    style={{ width: item.width }}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            {tableData.length === -1 ? (
              <Empty />
            ) : (
              <div className={styles.tbody}>
                {tableData.map((item, index) => {
                  return (
                    <div className={styles.tr} key={index}>
                      {tableColumns.map((it, idx) => {
                        return (
                          <div
                            className={styles.td}
                            key={idx}
                            style={{ width: it.width }}
                          >
                            {it.render
                              ? it.render(item[it.dataIndex], item)
                              : item[it.dataIndex]}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                <div className={classNames(styles.tr, styles.trTotal)}>
                  <div className={styles.td}>合计</div>
                  <div className={styles.td}>{total.betNum || 0}</div>
                  <div className={styles.td}>{total.betAmount || 0}</div>
                  <div className={styles.td}>
                    <span className={total.gameAmount > 0 ? "" : styles.red}>
                      {total.gameAmount || 0}
                    </span>
                  </div>
                  <div className={styles.td}>
                    <span className={total.betTax > 0 ? "" : styles.red}>
                      {total.betTax || 0}
                    </span>
                  </div>
                  <div className={styles.td}>
                    <span className={total.finalAmount > 0 ? "" : styles.red}>
                      {total.finalAmount || 0}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Content>
    </Main>
  );
}

export default inject("store")(observer(Report));
