const api = {
  register: "/v1/w/mem/register ", //注册
  login: "/v1/sys/login", //登录
  loginOut: "/v1/sys/loginOut", //  登出
  playInfo: "/v1/play/info", // 玩法规则
  currentIssue: "/v1/ic/currentIssue", // 当前期号信息
  historyIssue: "/v1/ic/hisIssue", // 历史期号信息
  issue: "/v1/ic/issue", // 指定期号信息
  bet: "/v1/bc/bet", // 投注
  historyOrder: "/v1/bc/hisOrder", // 查询投注记录··
  balance: "/v1/sys/balance", // 查询账户余额
  password: "/v1/sys/pwd", // 修改账户密码
  bonus: "/v1/play/my/queryPlayRebate", // 查询返水
  settlement: "/v1/bc/queryMyReport", // 个人结算报表
  settlementTotal: "/v1/bc/sumMyReport", //结算报表汇总
  noticeList: "/v1/sys/queryNoticeMsg", // 公告列表
  dicInfo: "/v1/sys/getDicInfo", // 全局配置
  bindAccessIp: "/v1/sys/bindAccessIp", // 绑定ip
};
export default api;
