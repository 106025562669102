import { observable, action } from "mobx";
import api from "../services/api";
import { http, httpGet } from "../services/fetcher";
import { getUser } from "../tool";
import { getLocalStore } from "../tool/localStore";

const store = observable({
  voucher: [],
  setVoucher: action((voucher) => {
    store.voucher = voucher;
  }),

  loadingCount: 0,
  setLoadingCount: action((count) => {
    store.loadingCount = count;
  }),

  animate: true,
  setAnimate: action((animate) => {
    store.animate = animate;
    setTimeout(
      action(() => {
        store.animate = true;
      }),
      100
    );
  }),

  orderMsg: null,
  setOrderMsg: action((orderMsg) => {
    store.orderMsg = orderMsg;
  }),

  uniKey: "",
  setUniKey: action(() => {
    store.uniKey = new Date().getTime();
  }),

  notice: "",
  setNotice: action(async (isClose) => {
    if (isClose) {
      store.notice = "";
      store.isShowMsgs = true;
    } else {
      let dic = getLocalStore("dic") || null;
      if (!dic) {
        const res = await http(api.allDic);
        if (res && res.result) {
          dic = res.result;
        }
      }
      const user = await getUser();
      if (user && dic && dic["alert.msg.app"]) {
        try {
          store.notice = JSON.parse(dic["alert.msg.app"]);
        } catch (error) {
          store.isShowMsgs = true;
        }
      } else {
        store.isShowMsgs = true;
      }
    }
  }),

  msgs: "",
  isShowMsgs: false,
  setMsgs: action(async (isClose) => {
    if (isClose) {
      store.msgs = "";
      store.isShowMsgs = false;
      return;
    }
    const res = await http(api.noticeList);
    if (res && res.result && res.result.length) {
      let readIsd = getLocalStore("readNotice", true) || [];
      const list = res.result.filter(
        (item) => readIsd.indexOf(item.rowId) == -1
      );
      if (list.length) {
        store.msgs = list;
      }
    }
  }),

  inform: false,
  setInform: action(() => {
    store.inform = true;
  }),
  userInfo: getLocalStore("acc")
    ? JSON.parse(getLocalStore("acc"))
    : {
        accountNo: "",
        amount: 0,
        balance: 0,
        betHall: "",
      },
  setUserInfo: action((info) => {
    store.userInfo = info;
  }),
  playInfo: getLocalStore("playInfo")
    ? JSON.parse(getLocalStore("playInfo"))
    : {
        playInfo: "{}",
      },
  setPlayInfo: action((info) => {
    store.playInfo = info;
  }),
  // 当期信息
  currentIssue: {},
  // 获取当期信息
  getcurrentIssue: action(async () => {
    const res = await httpGet(api.currentIssue);
    if (res.code === "C2") {
      store.currentIssue = res.result;
    }
  }),
  balanceInfo: {
    betHall: "",
    balance: 0,
    amount: 0,
  },
  getBalanceInfo: action(async () => {
    const res = await httpGet(api.balance);
    if (res.code === "C2") {
      store.balanceInfo = res.result;
    }
  }),
  // 获取全局信息
  dicInfo: {
    captchaId: "",
    version: "",
    enable: 0,
  },
  getDicInfo: action(async () => {
    const res = await httpGet(api.dicInfo);
    if (res.code === "C2") {
      store.dicInfo = res.result;
    }
  }),

  // 一级玩法
  firstPlay: {},
  setFirstPlay: action((play) => {
    store.firstPlay = play;
  }),
  // 二级玩法
  secondPlay: {},
  setSecondPlay: action((play) => {
    store.secondPlay = play;
  }),
  // 二级玩法
  secondIndex: {},
  setSecondIndex: action((play) => {
    store.secondIndex = play;
  }),
  // 投注成功标识，用于刷新最近投注
  betSuccessCount: 0,
  addBetSuccessCount: action(() => {
    store.betSuccessCount = store.betSuccessCount + 1;
  }),
  // 当前盘口
  currentHandicap: localStorage.getItem("handicap") || "",
  setCurrentHandicap: action((handicap) => {
    store.currentHandicap = handicap;
  }),
});

export default store;
