import { getUrlParamsToJson } from "../../tool";
import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import styles from "./index.module.scss";

export const VideoJS = (props) => {
  const urlParams = getUrlParamsToJson();
  const { url } = urlParams;
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const options = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: url,
        type: "video/mu38",
      },
    ],
  };

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
      }));
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className={styles.container}>
      <div data-vjs-player className={styles.videoContainer}>
        <div ref={videoRef} />
      </div>
    </div>
  );
};

export default VideoJS;
