import NumberCount from "../../../../../components/number";
import {
  classNames,
  zodiacBetArr,
  calcMantissa,
  calcHead,
} from "../../../../../tool";
import styles from "../index.module.scss";

// 尾数、一肖
export default function PTYXWS(props) {
  const {
    plays,
    selectPlays,
    onBet,
    playOddInfo,
    firstPlay,
    secondPlay,
    money,
  } = props;

  return (
    <div className={classNames(styles.playContainer, styles.PTYXWS)}>
      <div className={styles.groupItem}>
        <div className={styles.groupTitle}>
          <div className={styles.typeTitle}>{firstPlay.name}</div>
          <div className={styles.countTitle}>号</div>
          <div className={styles.oddTitle}>赔率</div>
          <div className={styles.amountTitle}>金额</div>
        </div>
        {plays.map((item, index) => {
          const active =
            selectPlays.findIndex((i) => i.code === item.code) !== -1;
          const betArr =
            firstPlay.name === "连尾" || firstPlay.name === "尾数"
              ? calcMantissa(index, 49)
              : zodiacBetArr[index];

          return (
            <div
              key={index}
              className={styles.playItem}
              onClick={() => onBet(item)}
            >
              <div className={styles.name}>{item.name}</div>
              <div className={styles.count}>
                {betArr.map((it, idx) => {
                  return (
                    <NumberCount
                      key={idx}
                      number={it}
                      className={styles.number}
                    />
                  );
                })}
              </div>
              <div className={styles.odds}>
                {
                  playOddInfo[
                    `LHC_${firstPlay.code}_${
                      secondPlay.code || firstPlay.code
                    }${item.code}${
                      zodiacBetArr[index].length > 4 &&
                      firstPlay.name === "一肖"
                        ? "0"
                        : ""
                    }`
                  ]
                }
                {item.code ? (
                  <img
                    className={styles.betImg}
                    src={require(`../../../../../assets/imgs/web/numSel${
                      active ? "" : "Not"
                    }.png`)}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className={styles.amount}>{active ? money : ""}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
