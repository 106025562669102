import { observer, inject } from "mobx-react";
import { useNavigate, useLocation } from "react-router-dom";
import { httpGet } from "../../services/fetcher";
import api from "../../services/api";
import styles from "./index.module.scss";
import { classNames } from "../../tool";
import { useEffect } from "react";

function CenterNav({ store }) {
  const {
    playInfo,
    firstPlay,
    setFirstPlay,
    setPlayInfo,
    setSecondPlay,
    setSecondIndex,
  } = store;
  const navigate = useNavigate();
  const location = useLocation();
  const rules =
    (JSON.parse(playInfo.playInfo || "{}").LHC || {}).playInfo || [];
  useEffect(() => {
    if (rules.length) {
      const second = rules[0].children ? rules[0].children[0] : {};
      setFirstPlay(rules[0]);
      setSecondPlay(second);
      setSecondIndex(0);
    }
  }, [rules.length]);

  useEffect(() => {
    // 请求玩法规则
    const getPlayInfo = async () => {
      const res = await httpGet(api.playInfo);
      if (res.code === "C2") {
        setPlayInfo(res.result);
      }
    };
    if (rules.length === 0) {
      getPlayInfo();
    }
  }, []);
  return (
    <div className={styles.centerNav}>
      <div
        className={styles.title}
        onClick={() => {
          navigate("/play");
        }}
      >
        新澳门六合彩
      </div>
      <div className={styles.navList}>
        {rules.map((item, index) => {
          return (
            <div
              className={classNames(
                styles.navItem,
                firstPlay.name === item.name ? styles.active : ""
              )}
              key={index}
              onClick={() => {
                if (
                  (firstPlay.name !== item.name &&
                    location.pathname === "/play") ||
                  location.pathname !== "/play"
                ) {
                  const second = item.children ? item.children[0] : {};
                  setSecondPlay(second);
                  setSecondIndex(0);
                  setFirstPlay(item);
                  navigate("/play");
                }
              }}
            >
              {item.name}
              {index < rules.length - 1 ? <span>｜</span> : ""}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default inject("store")(observer(CenterNav));
