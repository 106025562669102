import TM from "./TM"; // 特码 全不中 正码特 正码 连码
import TX from "./TX"; // 特肖、合肖 连肖
import PTYXWS from "./PTYXWS"; // 尾数、一肖
import ZMYL from "./ZMYL"; // 正码1-6
import ZONGX from "./ZONGX"; // 总肖
import EM from "./EM"; // 两面
import SB from "./SB"; // 色波
import TWS from "./TWS"; // 头尾数
import { memo } from "react";
function PlayContent(props) {
  const { selectPlays, onBet, firstPlay, secondPlay, playOddInfo, money } =
    props;
  let code = firstPlay.code;
  let plays = firstPlay.children ? secondPlay.plays : firstPlay.plays;
  if (plays && plays.length) {
    let Com = () => <span />;
    switch (code) {
      // 特码 全不中 正码特 正码 连码
      case "TM":
      case "ZXBZ":
      case "ZMT":
      case "ZM":
      case "LM":
        Com = () => (
          <TM
            money={money}
            plays={plays}
            onBet={onBet}
            selectPlays={selectPlays}
            playOddInfo={playOddInfo}
            firstPlay={firstPlay}
            secondPlay={secondPlay}
          />
        );
        break;
      case "TX":
      case "HX":
      case "ZX":
        Com = () => (
          <TX
            plays={plays}
            money={money}
            onBet={onBet}
            selectPlays={selectPlays}
            playOddInfo={playOddInfo}
            firstPlay={firstPlay}
            secondPlay={secondPlay}
          />
        );
        break;
      case "PTYXWS":
        Com = () => (
          <PTYXWS
            money={money}
            plays={plays}
            onBet={onBet}
            selectPlays={selectPlays}
            firstPlay={firstPlay}
            secondPlay={secondPlay}
            playOddInfo={playOddInfo}
          />
        );
      case "LXLW":
        if (firstPlay.name === "连肖") {
          Com = () => (
            <TX
              plays={plays}
              money={money}
              onBet={onBet}
              selectPlays={selectPlays}
              playOddInfo={playOddInfo}
              firstPlay={firstPlay}
              secondPlay={secondPlay}
            />
          );
        } else if (firstPlay.name === "连尾") {
          Com = () => (
            <PTYXWS
              plays={plays}
              money={money}
              onBet={onBet}
              selectPlays={selectPlays}
              firstPlay={firstPlay}
              secondPlay={secondPlay}
              playOddInfo={playOddInfo}
            />
          );
        }

        break;
      case "ZMYL":
        Com = () => (
          <ZMYL
            plays={plays}
            money={money}
            onBet={onBet}
            selectPlays={selectPlays}
            firstPlay={firstPlay}
            secondPlay={secondPlay}
            playOddInfo={playOddInfo}
          />
        );
        break;
      case "ZONGX":
        Com = () => (
          <ZONGX
            plays={plays}
            money={money}
            onBet={onBet}
            selectPlays={selectPlays}
            playOddInfo={playOddInfo}
            firstPlay={firstPlay}
            secondPlay={secondPlay}
          />
        );
        break;
      case "EM":
        Com = () => (
          <EM
            plays={plays}
            money={money}
            onBet={onBet}
            selectPlays={selectPlays}
            playOddInfo={playOddInfo}
            firstPlay={firstPlay}
            secondPlay={secondPlay}
          />
        );
        break;
      case "SB":
        Com = () => (
          <SB
            money={money}
            plays={plays}
            onBet={onBet}
            selectPlays={selectPlays}
            firstPlay={firstPlay}
            secondPlay={secondPlay}
            playOddInfo={playOddInfo}
          />
        );
        break;
      case "TWS":
        Com = () => (
          <TWS
            money={money}
            plays={plays}
            onBet={onBet}
            selectPlays={selectPlays}
            playOddInfo={playOddInfo}
            firstPlay={firstPlay}
            secondPlay={secondPlay}
          />
        );
        break;
      default:
        break;
    }
    return <Com />;
  } else {
    return <span />;
  }
}

export default memo(PlayContent, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
