export const DescList = [
  {
    title: "特码",
    content: [
      {
        title: "特码",
        text: "选1个或1个以上号码，若开奖号码的特别码与所选号码一致，即为中奖；如投注方案为45，开奖号码为：04,25,36,22,44,30 + 45，即中特码。",
      },
    ],
  },
  {
    title: "两面",
    content: [
      {
        title: "两面",
        text: "选1个或1个以上号码，如果当期特码开出符合投注选择种类，即为中奖； 若当期特别码为49，则特大,特小,特尾大,特尾小,特单,特双,特大单,特大双,特小单,特小双,特合大,特合小,特合单,特合双都为和局。",
      },
      {
        title: "特大、特小",
        text: "开出之特码大于或等于25为特码大，小于或等于24为特码小，",
      },
      {
        title: "特单、特双",
        text: "特码为偶数叫特双，为奇数叫特单，",
      },
      {
        title: "特大单",
        text: "开出之特码大于或等于25的单数为特码大单，如33、37，",
      },
      {
        title: "特小单",
        text: "开出之特码小于或等于23的单数为特码小单，如17、21，",
      },
      {
        title: "特大双",
        text: "开出之特码大于或等于26的双数为特码大双，如28、32，",
      },
      {
        title: "特小双",
        text: "开出之特码小于或等于24的双数为特码小双，如18、22，",
      },
      {
        title: "特码合数",
        text: [
          "特码个位数和十位数之和值，",
          "特合小：和值小于或等于6合数小，如1； 特合大：和值大于或等于7，如26；",
          "特合单：和值为奇数，如21，特合双：和值为偶数，如33；",
          "特尾小：开出之特码尾数（0-4），特尾大：开出之特码尾数（5-9）,",
        ],
      },
      {
        title: "总单、总双",
        text: "所有七个开奖号码的分数总和是单数叫(总分单) ，如分数总和是103、193；分数总和是双数叫(总分双)，如分数总和是108、160；",
      },
      {
        title: "总大、总小",
        text: "所有七个开奖号码的分数总和大于或等于175为总分大；分数总和小于或等于174为总分小。如开奖号码为01、07、15、29、38、46、24，分数总和是160，则总分小。",
      },
    ],
  },
  {
    title: "合肖",
    content: [
      {
        title: "合肖",
        text: "选2~11生肖(排列如同生肖)为一组合，若开奖号码的特码亦在此组合内，即视为中奖",
      },
    ],
  },
  {
    title: "特肖",
    content: [
      {
        title: "特肖",
        text: "选1个或1个以上生肖，所选生肖与特别码所属生肖一致，即为中奖。",
      },
    ],
  },
  {
    title: "正码",
    content: [
      {
        title: "正码",
        text: "当期开出之前6个号码叫正码。第一时间出来的叫正码1，依次叫正码2、正码3……正码6，不以大小排序。 选1个或1个以上号码，所选号码在当期开奖号码的正码内，即为中奖。",
      },
    ],
  },

  {
    title: "正码1-6",
    content: [
      {
        title: "正码1-6",
        text: "正码1、正码2、正码3、正码4 、正码5、正码6的大小单双、波色、合数单双与特码的大小单双、波色、合数单双规则相同， 如正码1为25，则正码1为大，为单，为蓝波，为尾大；正码2为2， 则正码2为小，为双，为红波，为尾小；号码为49时退还本金，则大小单双、合数单双为和，波色为绿波，尾数大小为和。",
      },
    ],
  },

  {
    title: "色波",
    content: [
      {
        text: "选1个或1个以上波色，所选波色与当期特别码的波色一致，即为中奖",
        title: "色波",
      },
      {
        text: "01, 02, 07, 08, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46；",
        color: "red",
        title: "红波",
      },
      {
        text: "03, 04, 09, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48；",
        color: "#438cd0",
        title: "蓝波",
      },
      {
        text: "05, 06, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49。",
        color: "green",
        title: "绿波",
      },
      {
        title: "半波半半波",
        text: [
          "以特别码色波和特别码单双及特别码大小等三种游戏为一个投注组合，当期特别码开出符合投注组合，即视为中奖；若特别码为49算作和局。",
          "红单 : 红波中的奇数；红双 : 红波中的偶数；红大 : 红波中大于或等于25的数；红小 : 红波中小于或等于24的数。",
          "蓝单 : 蓝波中的奇数；蓝双 : 蓝波中的偶数；蓝大 : 蓝波中大于或等于25的数；蓝小: 蓝波中小于或等于24的数。",
          "绿单 : 绿波中的奇数；绿双 : 绿波中的偶数；绿大 : 绿波中大于或等于25的数；绿小 : 绿波中小于或等于24的数。",
          "红大单 : 红波中大于或等于25，且是奇数；红大双 : 红波中大于或等于25，且是偶数；红小单 : 红波中小于或等于24，且是奇数；红小双 : 红波中小于或等于24，且为偶数。",
          "蓝大单 : 蓝波中大于或等于25，且是奇数；蓝大双 : 蓝波中大于或等于25，且是偶数；蓝小单 : 蓝波中小于或等于24，且是奇数；蓝小双 : 蓝波中小于或等于24，且为偶数。",
          "绿大单 : 绿波中大于或等于25，且是奇数；绿大双 : 绿波中大于或等于25，且是偶数；绿小单 : 绿波中小于或等于24，且是奇数；绿小双 : 绿波中小于或等于24，且为偶数。",
        ],
      },
    ],
  },
  {
    title: "连码",
    content: [
      {
        title: "三全中",
        text: "每三个号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖。如03、04、05三个都是开奖号码之正码，视为中奖，如两个正码加上一个特别号码视为不中奖。",
      },
      {
        title: "三中二",
        text: "所投注的每三个号码为一组合，若其中2个是开奖号码中的正码，视为中奖，叫三中二；若3个都是开奖号码中的 正码，叫三中二之中三，其余情形视为不中奖， 如03、04、05为一组合，开奖号码中有03 、04两个正码，没有05，叫三中二，按三中二赔付；如开奖号码中有03、04、05三个正码，叫三中二之中三，按中三赔付；如出现1个或没有，视为不中奖。",
      },
      {
        title: "二全中",
        text: "每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖（含一个正码加一个特别号码之情形）。",
      },
      {
        title: "二中特",
        text: "每二个号码为一组合，二个号码都是开奖号码之正码，叫二中特之二中；若其中一个是正码，一个是特别号码，叫二中特之中特；其余情形视为不中奖。",
      },
      {
        title: "特串",
        text: "每二个号码为一组合，其中一个是正码，一个是特别号码，视为中奖，其余情形视为不中奖（含二个号码都是正码之情形）。",
      },
      {
        title: "四全中",
        text: "每四个号码为一个组合，若四个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖。如03、04、05、06四个都是开奖号码之正码，视为中奖，如三个正码加上一个特别号码视为不中奖",
      },
    ],
  },
  {
    title: "总肖",
    content: [
      {
        title: "总肖",
        text: "当期号码(所有正码与最后开出的特别码)开出的不同生肖总数，与所投注之预计开出之生肖总和数(不用指定特定生肖)，则视为中奖，其余情形视为不中奖；当期号码（正码和特别码）开出的不同生肖总数若为单数则为单，若为双数则为双。",
      },
    ],
  },
  {
    title: "正肖",
    content: [
      {
        title: "正肖",
        text: "当期开奖的前6个号码(不含特别码，不分先后顺序)，其中有一个号码在投注的生肖范围即算中奖。如果有多个球号开在投注生肖范围内，派彩金额将自动倍增。",
      },
    ],
  },
  {
    title: "一肖",
    content: [
      {
        title: "一肖",
        title: "一肖",
        text: "指开出的7个开奖号码中含有投注所属生肖的一个或多个号码，即视为中奖，不论同生肖的号码出现一个或多个，派彩只派一次。",
      },
    ],
  },
  {
    title: "尾数",
    content: [
      {
        title: "尾数",
        text: "指7个开奖号码中含有所属​​尾数的一个或多个号码，不论同尾数的号码出现一个或多个，派彩只派一次。",
      },
    ],
  },
  {
    title: "头尾数",
    content: [
      {
        title: "头尾数",
        text: "所选头、尾数与特别码的十位、个位一致，即为中奖。",
      },
      {
        title: "0头",
        text: "  01，02，03，04，05，06，07，08，09；",
      },
      {
        title: "1头",
        text: " 10，11，12，13，14，15，16，17，18，19；",
      },
      {
        title: "2头",
        text: " 20，21，22，23，24，25，26，27，28，29；",
      },
      {
        title: "3头",
        text: " 30，31，32，33，34，35，36，37，38，39；",
      },
      {
        title: "4头",
        text: " 40，41，42，43，44，45，46，47，48，49；",
      },

      {
        title: "0尾",
        text: "  10，20，30，40;",
      },
      {
        title: "1尾",
        text: " 01，11，21，31，41；",
      },
      {
        title: "2尾",
        text: " 02，12，22，32，42；",
      },
      {
        title: "3尾",
        text: " 03，13，23，33，43；",
      },
      {
        title: "4尾",
        text: " 04，14，24，34，44；",
      },
      {
        title: "5尾",
        text: " 05，15，25，35，45；",
      },
      {
        title: "6尾",
        text: " 06，16，26，36，46；",
      },
      {
        title: "7尾",
        text: " 07，17，27，37，47；",
      },
      {
        title: "8尾",
        text: " 08，18，28，38，48；",
      },
      {
        title: "9尾",
        text: " 09，19，29，39，49；",
      },
    ],
  },
  {
    title: "连肖",
    content: [
      {
        title: "连肖",
        text: "挑选2~5生肖（排列如同生肖）为一个组合，一个生肖对应多个号码，不论同生肖的号码出现一个或多个，派彩只派一次。每个生肖都有自己的赔率，下注组合的总赔率，取该组合生肖的最低赔率为总赔率。请注意49亦算输赢，不为和",
      },
      {
        title: "二连肖",
        text: "选择二个生肖为一投注组合进行下注。该注的二个生肖必须在当期开出的7个开奖号码相对应的生肖中，视为中奖。",
      },
      {
        title: "三连肖",
        text: "选择三个生肖为一投注组合进行下注。该注的三个生肖必须在当期开出的7个开奖号码相对应的生肖中，视为中奖。",
      },
      {
        title: "四连肖",
        text: "选择四个生肖为一投注组合进行下注。该注的四个生肖必须在当期开出的7个开奖号码相对应的生肖中，视为中奖。",
      },
      {
        title: "五连肖",
        text: "选择五个生肖为一投注组合进行下注。该注的五个生肖必须在当期开出的7个开奖号码相对应的生肖中，视为中奖。",
      },
    ],
  },
  {
    title: "连尾",
    content: [
      {
        title: "连尾",
        text: "挑选2~5个尾数为一组合；一个尾数对应多个号码，不论同尾数的号码出现一个或多个，派彩只派一次。每个尾数都有自己的赔率，下注组合的总赔率，取该组合尾数的最低赔率为总赔率。请注意49亦算输赢，不为和",
      },
      {
        title: "二连尾",
        text: "选择二个尾数为一投注组合进行下注。该注的二个尾数必须在当期开出的7个开奖号码相对应的尾数中，视为中奖。 ",
      },
      {
        title: "三连尾",
        text: "选择三个尾数为一投注组合进行下注。该注的三个尾数必须在当期开出的7个开奖号码相对应的尾数中，视为中奖。 ",
      },
      {
        title: "四连尾",
        text: "选择四个尾数为一投注组合进行下注。该注的四个尾数必须在当期开出的7个开奖号码相对应的尾数中，视为中奖。 ",
      },
      {
        title: "五连尾",
        text: "选择五个尾数为一投注组合进行下注。该注的五个尾数必须在当期开出的7个开奖号码相对应的尾数中，视为中奖。 ",
      },
    ],
  },
  {
    title: "全不中",
    content: [
      {
        title: "五不中",
        text: "选择5个或5个以上号码组成1注，所选号码不包含在开奖号码中，即为中奖。如投注方案为1-2-3-4-5，开奖号码为6,7,8,9,10,11 + 12，即中五不中。",
      },
      {
        title: "六不中",
        text: "选择6个或6个以上号码组成1注，所选号码不包含在开奖号码中，即为中奖。如投注方案为1-2-3-4-5-13，开奖号码为6,7,8,9,10,11 + 12，即中六不中。",
      },
      {
        title: "七不中",
        text: "选择7个或7个以上号码组成1注，所选号码不包含在开奖号码中，即为中奖。如投注方案为1-2-3-4-5-13-14，开奖号码为6,7,8,9,10,11 + 12，即中七不中。",
      },
      {
        title: "八不中",
        text: "选择8个或8个以上号码组成1注，所选号码不包含在开奖号码中，即为中奖。如投注方案为1-2-3-4-5-13-14-15，开奖号码为6,7,8,9,10,11 + 12，即中八不中。",
      },
      {
        title: "九不中",
        text: "选择9个或9个以上号码组成1注，所选号码不包含在开奖号码中，即为中奖。如投注方案为1-2-3-4-5-13-14-15-16，开奖号码为6,7,8,9,10,11 + 12，即中九不中。",
      },
      {
        title: "十不中",
        text: "选择10个或10个以上号码组成1注，所选号码不包含在开奖号码中，即为中奖。如投注方案为1-2-3-4-5-13-14-15-16-17，开奖号码为6,7,8,9,10,11 + 12，即中十不中。",
      },
      {
        title: "十一不中",
        text: "选择11个或11个以上号码组成1注，所选号码不包含在开奖号码中，即为中奖。如投注方案为1-2-3-4-5-13-14-15-16-17-18，开奖号码为6,7,8,9,10,11 + 12，即中十一不中。",
      },
      {
        title: "十二不中",
        text: "选择12个或12个以上号码组成1注，所选号码不包含在开奖号码中，即为中奖。如投注方案为1-2-3-4-5-13-14-15-16-17-18-19，开奖号码为6,7,8,9,10,11 + 12，即中十二不中。",
      },
      {
        title: "根据十二生肖其动物特性来区分【应用于特码生肖、6肖等项目】",
        text: [
          "天肖 : 兔 马 猴 猪 牛 龙",
          "地肖 : 鼠 虎 蛇 羊 鸡 狗",
          "家肖 : 羊 马 牛 猪 狗 鸡",
          "野肖 : 猴 蛇 龙 兔 虎 鼠",
          "前肖 : 鼠 牛 虎 兔 龙 蛇",
          "后肖 : 马 羊 猴 鸡 狗 猪",
        ],
      },
    ],
  },
];

export const Rules1 = {
  title: "重要声明",
  text: [
    "1.如果客戶怀疑自己的资料被盗用，应立即通知本公司，并更改详细资料，以前的使用者名称及密码将全部无效。",
    "2.客戶有责任确保自己的帐戶及登入资料的保密性。以使用者名称及密码进行的任何网上投注将被视为有效。",
    "3.公布赔率时出现的任何打字错误或非故意人为失误，本公司保留改正错误和按正确赔率结算投注的权力。您居住所在地的法律有可能规定网路博奕不合法；若此情况属实，本公司将不会批准您使用付帐卡进行交易。",
    "4.每次登入时客戶都应该核对自己的帐戶结余额。如对余额有任何疑问，请在第一时间内通知本公司。",
    "5.一旦投注被接受，则不得取消或修改。",
    "6.所有号码赔率将不时浮动，派彩时的赔率将以确认投注时之赔率为准。",
    "7.每注最高投注金额按不同[场次]及[投注项目]及[会员帐号]设定浮动。如投注金额超过上述设定，本公司有权取消超过之投注金额。",
    "8.所有投注都必须在开奖前时间内进行否则投注无效。",
    "9.所有投注派彩彩金皆含本金。",
  ],
};

export const Rules2 = {
  title: "游戏规则说明",
  text: ["1.以下所有投注皆含本金。", "2.具体游戏规则如下:"],
};
