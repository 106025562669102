import { classNames } from "../../../../../tool";
import styles from "../index.module.scss";
export default function ZMYL(props) {
  const { selectPlays, onBet, firstPlay, playOddInfo, money } = props;
  const plays = firstPlay.children;
  return (
    <div className={classNames(styles.playContainer, styles.ZMYL)}>
      {plays.map((item, index) => {
        return (
          <div key={index} className={styles.groupItem}>
            <div className={styles.playItemTitle}>{item.name}</div>
            <div className={styles.groupTitle}>
              <div className={styles.countTitle}>号</div>
              <div className={styles.oddTitle}>赔率</div>
              <div className={styles.amountTitle}>金额</div>
            </div>

            {item.plays.map((it, idx) => {
              const active =
                selectPlays.findIndex(
                  (i) => i.code === it.code && item.code === i.secondCode
                ) !== -1;
              return (
                <div
                  key={index + idx}
                  className={classNames(
                    styles.playItem,
                    idx > item.plays.length - 4 ? styles.last : ""
                  )}
                  onClick={() => onBet(it, item)}
                >
                  <div className={styles.name}>{it.name}</div>
                  <div className={styles.odds}>
                    {
                      playOddInfo[
                        `LHC_${firstPlay.code}_${item.code}${it.code}`
                      ]
                    }
                    {item.code ? (
                      <img
                        className={styles.betImg}
                        src={require(`../../../../../assets/imgs/web/numSel${
                          active ? "" : "Not"
                        }.png`)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={styles.amount}>{active ? money : ""}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
