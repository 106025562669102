import { classNames, zodiacBetArr } from "../../../../../tool";
import Number from "../../../../../components/number";
import styles from "../index.module.scss";
export default function TX(props) {
  const {
    plays,
    selectPlays,
    onBet,
    playOddInfo,
    firstPlay,
    secondPlay,
    money,
  } = props;
  return (
    <div className={classNames(styles.playContainer, styles.TX)}>
      <div className={styles.groupItem}>
        <div className={styles.groupTitle}>
          <div className={styles.typeTitle}>{firstPlay.name}</div>
          <div className={styles.countTitle}>号</div>
          <div className={styles.oddTitle}>赔率</div>
          <div className={styles.amountTitle}>金额</div>
        </div>
        {plays.map((item, index) => {
          const active =
            selectPlays.findIndex((i) => i.code === item.code) !== -1;
          return (
            <div
              key={index}
              className={styles.playItem}
              onClick={() => onBet(item)}
            >
              <div className={styles.name}>{item.name}</div>
              <div className={styles.count}>
                {zodiacBetArr[index].map((it, idx) => {
                  return (
                    <Number key={idx} number={it} className={styles.number} />
                  );
                })}
              </div>
              <div className={styles.odds}>
                {
                  playOddInfo[
                    `LHC_${firstPlay.code}_${
                      secondPlay.code || firstPlay.code
                    }${item.code}${zodiacBetArr[index].length > 4 ? "0" : ""}`
                  ]
                }
                {item.code ? (
                  <img
                    className={styles.betImg}
                    src={require(`../../../../../assets/imgs/web/numSel${
                      active ? "" : "Not"
                    }.png`)}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className={styles.amount}>{active ? money : ""}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
