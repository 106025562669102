import { useEffect } from "react";
import { useSetState } from "ahooks";
import { useNavigate } from "react-router-dom";
import Main from "../../components/main";
import Content from "../../components/content";
import Empty from "../../components/empty";
import styles from "./index.module.scss";
import { http } from "../../services/fetcher";
import api from "../../services/api";
function Settlement() {
  const navigate = useNavigate();
  const tableColumns = [
    {
      title: "游戏名称",
      dataIndex: "cTime",
      render: (val) => {
        return (
          <span onClick={() => navigate("/settlementDetail")}>
            新澳门六合彩
          </span>
        );
      },
    },
    {
      title: "注单数",
      dataIndex: "betNum",
      render: (val) => {
        return val || 0;
      },
    },
    {
      title: "下注金额",
      dataIndex: "betAmount",
      render: (val) => {
        return val || 0;
      },
    },
  ];
  const [state, setState] = useSetState({
    tableData: [{ betNum: 0, betAmount: 0 }],
  });
  const { tableData } = state;
  const getData = () => {
    const getSettelmentTotal = async () => {
      const res = await http(api.settlementTotal, { state: 0 });
      if (res.code === "C2") {
        const data = res.result;
        setState({
          tableData: [data],
        });
      }
    };
    getSettelmentTotal();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Main>
      <Content className={styles.content}>
        <div className={styles.innerContent}>
          <div className={styles.table}>
            <div className={styles.thead}>
              {tableColumns.map((item, index) => {
                return (
                  <div
                    className={styles.th}
                    key={index}
                    style={{ width: item.width }}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>

            <div className={styles.tbody}>
              {tableData.map((item, index) => {
                return (
                  <div className={styles.tr} key={index}>
                    {tableColumns.map((it, idx) => {
                      return (
                        <div
                          className={styles.td}
                          key={idx}
                          style={{ width: it.width }}
                        >
                          {it.render
                            ? it.render(item[it.dataIndex], item)
                            : item[it.dataIndex]}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Content>
    </Main>
  );
}

export default Settlement;
