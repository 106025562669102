import { useEffect } from "react";
import { useSetState } from "ahooks";
import { Select } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { observer, inject } from "mobx-react";
import NumberCount from "../../components/number";
import { http } from "../../services/fetcher";
import api from "../../services/api";
import styles from "./index.module.scss";
import {
  classNames,
  calcNumZodiac,
  calculateSpecialCodeAnimal,
  getTotal,
  caclDanShuang,
  caclSize,
} from "../../tool";
import Toast from "../../components/toast";

function LeftInfo({ store }) {
  const {
    balanceInfo,
    getBalanceInfo,
    currentIssue,
    playInfo,
    betSuccessCount,
    currentHandicap,
    setCurrentHandicap,
  } = store;
  const { accountNo, balance } = balanceInfo;
  const rules =
    (JSON.parse(playInfo.playInfo || "{}").LHC || {}).playInfo || [];

  const [state, setState] = useSetState({
    handicapColumns: [],
    trendData: [],
    trendVisible: false,
    orderData: [],
    formOrderData: [],
    refreshCount: 0,
  });
  const {
    handicapColumns,
    trendData,
    trendVisible,
    orderData,
    formOrderData,
    refreshCount,
  } = state;
  // 获取历史开奖信息
  const queryHistory = async (params) => {
    const res = await http(api.historyIssue, params);
    if (res.code === "C2") {
      const records = res.result.records;
      let arr = [];
      arr = records;
      arr = arr
        .filter((i) => i.lotteryResult)
        .map((item) => {
          const resultArr = JSON.parse(JSON.stringify(item.lotteryResult));
          resultArr.splice(resultArr.length - 1, 0, "+");
          const last = resultArr[resultArr.length - 1];
          const lastArr = last.split("");
          const lastTotal = getTotal(lastArr);
          const lastZoadic = calculateSpecialCodeAnimal(last, item?.etime);
          const size = caclSize(resultArr[7], 49, true);
          const danShuang = caclDanShuang(resultArr[7], true);
          const lastTotalSize = caclSize(lastTotal, 13, true);
          const lastTotalDanShuang = caclDanShuang(lastTotal, true);
          return {
            ...item,
            last,
            lastZoadic,
            lastTotal,
            size,
            danShuang,
            lastTotalSize,
            lastTotalDanShuang,
          };
        });
      setState({ trendData: arr });
    }
  };

  const queryOrder = async (params) => {
    const realParams = { ...params };
    const res = await http(api.historyOrder, realParams);
    if (res.code === "C2") {
      const records = res.result.records;
      setState({
        orderData: records,
        refreshCount: refreshCount + 1,
      });
    }
  };
  useEffect(() => {
    if (balanceInfo && balanceInfo.betHall) {
      const betHall = balanceInfo.betHall;
      const betHallArr =
        (betHall || "").split("_").filter((i) => i !== "") || [];
      const handicapArr = betHallArr.map((i) => {
        return {
          label: i + "盘",
          value: i,
        };
      });
      // 当前盘口是否在开通盘口中
      const isIn = betHallArr.findIndex((i) => i === currentHandicap) !== -1;
      const hadicap = currentHandicap && isIn ? currentHandicap : betHallArr[0];
      localStorage.setItem("hadicap", hadicap);
      setCurrentHandicap(hadicap);
      setState({
        handicapColumns: handicapArr,
      });
    }
  }, [balanceInfo.betHall]);
  useEffect(() => {
    // 请求余额
    getBalanceInfo();
    // 默认请求历史开奖
    queryHistory({
      page: {
        current: 1,
        size: 10,
      },
    });
  }, []);

  useEffect(() => {
    if (currentIssue.rowId) {
      queryOrder({
        state: 0,
        page: {
          current: 1,
          size: 10,
        },
      });
    }
  }, [currentIssue, betSuccessCount]);

  useEffect(() => {
    if (rules.length) {
      const newData = (orderData || []).map((item) => {
        return {
          ...item,
          betContentFromat: getPlayItem(item),
        };
      });
      setState({
        formOrderData: newData,
      });
    }
  }, [rules.length, orderData.length, refreshCount]);

  const getPlayItem = (item) => {
    const { playCode2, playCode3, betContent } = item;
    let plays = [];
    rules.forEach((i) => {
      if (i.code === playCode2) {
        if (i.plays) {
          plays = i.plays;
        } else {
          const secondPlay =
            i.children.find((it) => it.code === playCode3) || {};
          plays = secondPlay.plays || [];
        }
      }
    });
    const betContentFromat = plays.length
      ? betContent.map((it) => {
        const item = plays.find((i) => i.code === it);
        return item ? item.name : it;
      })
      : betContent;
    return betContentFromat;
  };
  return (
    <div className={styles.leftInfo}>
      <div className={styles.title}>请检视以下的账户信息</div>
      <div className={styles.leftInner}>
        <div className={styles.userInfo}>
          <div className={styles.item}>
            <div className={styles.label}>会员账户</div>
            <div className={classNames(styles.value, styles.accountValue)}>
              <span>{accountNo}</span>
              <div
                className={styles.updateBtn}
                onClick={() => getBalanceInfo()}
              >
                更新
              </div>
            </div>
          </div>
          <div className={classNames(styles.item, styles.betHallItem)}>
            <div className={styles.label}>
              <span>盘</span>
              <span>口</span>
            </div>
            <div className={styles.value}>
              <Select
                value={currentHandicap}
                style={{ width: 72 }}
                onChange={(val) => {
                  setCurrentHandicap(val);
                  localStorage.setItem("handicap", val);
                }}
                options={handicapColumns}
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>信用额度</div>
            <div className={styles.value}>{balance}(RMB)</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>可用金额</div>
            <div className={styles.value}>{balance}</div>
          </div>
        </div>
        <div className={styles.trendContainer}>
          <div
            className={styles.trendTitle}
            onClick={() => setState({ trendVisible: !trendVisible })}
          >
            最近十期 特码 走势
          </div>
          <div
            className={classNames(
              styles.trendList,
              trendVisible ? styles.show : ""
            )}
          >
            {trendData.map((item, index) => {
              return (
                <div className={styles.trendItem} key={index}>
                  <div className={styles.trendValue}>{item.rowId}</div>
                  <div className={styles.trendValue}>
                    <NumberCount
                      width={"30px"}
                      height={"30px"}
                      number={item.last}
                    />
                  </div>
                  <div className={styles.trendValue}>{item.lastZoadic}</div>
                  <div
                    className={classNames(
                      styles.trendValue,
                      item.danShuang === "单" ? styles.blue : styles.yellow
                    )}
                  >
                    {item.danShuang}
                  </div>
                  <div
                    className={classNames(
                      styles.trendValue,
                      item.size === "小" ? styles.blue : styles.yellow
                    )}
                  >
                    {item.size}
                  </div>
                  <div
                    className={classNames(
                      styles.trendValue,
                      item.lastTotalSize === "小" ? styles.blue : styles.yellow
                    )}
                  >
                    {item.lastTotalSize}
                  </div>
                  <div
                    className={classNames(
                      styles.trendValue,
                      item.lastTotalDanShuang === "单"
                        ? styles.blue
                        : styles.yellow
                    )}
                  >
                    {item.lastTotalDanShuang}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.orderContainer}>
          <div className={styles.orderTitle}>最近投注</div>
          <div className={styles.issueTitle}>
            新澳门六合彩 -{" "}
            {currentIssue.rowId ? "第" + currentIssue.rowId + "期" : "暂无期数"}
          </div>
          <div className={styles.orderList}>
            {formOrderData.length ? (
              <>
                {formOrderData.map((item, index) => {
                  return (
                    <div key={index} className={styles.orderItem}>
                      <div className={styles.issue}>
                        <div className={styles.label}>注单号：</div>
                        <div className={styles.value}>
                          <CopyToClipboard
                            text={item.rowId}
                            onCopy={() => {
                              Toast({ msg: "复制成功" });
                            }}
                          >
                            <span>{item.rowId}</span>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className={styles.betPlays}>
                        <span className={styles.playCode2Name}>
                          {item.playCode3Name || item.playCode2Name} -{" "}
                        </span>
                        <span className={styles.betList}>
                          {item.betContentFromat &&
                            item.betContentFromat.map((i, idx) => {
                              return (
                                <span key={i}>
                                  {item.playCode2Name === "尾数" &&
                                    item.playCode3Name === "尾数"
                                    ? `${i}尾`
                                    : i}
                                  {idx < item.betContentFromat.length - 1
                                    ? ","
                                    : ""}
                                </span>
                              );
                            })}
                          <span
                            className={styles.odds}
                          >{` @ ${item.betOdds}`}</span>
                        </span>
                      </div>
                      <div className={styles.betAmount}>
                        <div className={styles.label}>下注额：</div>
                        <div className={styles.value}>￥{item.betAmount}</div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className={styles.empty}>暂无投注！</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject("store")(observer(LeftInfo));
