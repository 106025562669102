import { classNames, groupArr } from "../../../../../tool";
import Number from "../../../../../components/number";
import styles from "../index.module.scss";
export default function TM(props) {
  const {
    plays,
    selectPlays,
    onBet,
    playOddInfo,
    firstPlay,
    secondPlay,
    money,
  } = props;
  const groupPlays = groupArr(plays);
  return (
    <div className={classNames(styles.playContainer, styles.TM)}>
      {groupPlays.map((itm, idx) => {
        return (
          <div className={styles.groupItem} key={idx}>
            <div className={styles.groupTitle}>
              <div className={styles.countTitle}>号</div>
              <div className={styles.oddTitle}>赔率</div>
              <div className={styles.amountTitle}>金额</div>
            </div>
            {itm.map((item, index) => {
              const active =
                selectPlays.findIndex((i) => i.code === item.code) !== -1;
              return (
                <div
                  key={index}
                  className={styles.playItem}
                  onClick={() => {
                    if (item.code) {
                      onBet(item);
                    }
                  }}
                >
                  <div className={styles.count}>
                    {item.code ? (
                      <Number className={styles.number} number={item.name} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={classNames(
                      styles.odds,
                      item.minOdds ? styles.minOdds : ""
                    )}
                  >
                    {item.minOdds
                      ? `${
                          playOddInfo[
                            `LHC_${firstPlay.code}_${
                              secondPlay.code || firstPlay.code
                            }${item.code}0`
                          ]
                        }/`
                      : ""}
                    {
                      playOddInfo[
                        `LHC_${firstPlay.code}_${
                          secondPlay.code || firstPlay.code
                        }${item.code}`
                      ]
                    }
                    {item.code ? (
                      <img
                        className={styles.betImg}
                        src={require(`../../../../../assets/imgs/web/numSel${
                          active ? "" : "Not"
                        }.png`)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={styles.amount}>{active ? money : ""}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
