import { useNavigate, useLocation } from "react-router-dom";
import { setLocalStore } from "../../tool/localStore";
import styles from "./index.module.scss";
import { classNames } from "../../tool";

function NavHead() {
  //"未结明细｜报表查询｜开奖结果｜个人咨询｜登陆日志｜修改密码｜游戏规则｜"
  const navList = [
    {
      title: "未结明细",
      path: "/settlement",
    },
    {
      title: "报表查询",
      path: "/report",
    },
    {
      title: "开奖结果",
      path: "/history",
    },
    {
      title: "个人咨询",
      path: "/info",
    },
    {
      title: "修改密码",
      path: "/password",
    },
    {
      title: "游戏规则",
      path: "/gameDesc",
    },
    {
      title: "登出",
      path: "",
      func: () => {
        setLocalStore("token", "");
        localStorage.removeItem("login");
        localStorage.removeItem("handicap");
        localStorage.removeItem("select");
        navigate("/login");
      },
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={styles.header}>
      <div className={styles.empty} />
      <div className={styles.list}>
        {navList.map((item, index) => {
          return (
            <div
              className={classNames(
                styles.navItem,
                location.pathname === item.path ? styles.active : ""
              )}
              key={index}
              onClick={() => {
                if (item.path) {
                  navigate(item.path);
                } else if (item.func) {
                  item.func();
                }
              }}
            >
              {item.title}
              <span>｜</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NavHead;
