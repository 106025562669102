import { classNames } from "../../tool";
import styles from "./index.module.scss";

export default function Empty(props) {
  const { text = "暂无数据", className } = props;
  return (
    <div className={classNames(styles.empty, className)}>
      <img src={require("../../assets/imgs/empty.png")} />
      <div className={styles.text}>{text}</div>
    </div>
  );
}
