import React, { useEffect, useRef } from "react";
import toast from "../../../../components/toast";
import Modal from "../../../../components/modal";
import { classNames, caclCurrentZodiac, toFixedString } from "../../../../tool";
import styles from "./index.module.scss";
import { http } from "../../../../services/fetcher";
import api from "../../../../services/api";

const BetArea = (props) => {
  const {
    visible,
    balanceInfo,
    selectPlays,
    showSelectPlays,
    currentIssue,
    firstPlay,
    secondPlay,
    onBetSuccess,
    currentHandicap,
    playOddInfo,
    onClose,
    onDelete,
    getBalanceInfo,
    money,
  } = props;

  const isSpecialRef = useRef(
    (firstPlay.code === "HX" ||
      firstPlay.code === "LXLW" ||
      firstPlay.code === "LM" ||
      firstPlay.code === "ZXBZ") &&
      showSelectPlays.length > 0
  );
  const realSelectPlaysRef = useRef(false);
  useEffect(() => {
    realSelectPlaysRef.current = selectPlays;
    if (
      (firstPlay.code === "HX" ||
        firstPlay.code === "LXLW" ||
        firstPlay.code === "LM" ||
        firstPlay.code === "ZXBZ") &&
      showSelectPlays.length > 0
    ) {
      isSpecialRef.current = true;
      const arr = showSelectPlays.map((item) => {
        const codeArrInit = item.map((i) => i.code);
        const codeArr = JSON.parse(JSON.stringify(codeArrInit)).reverse();
        const realName = codeArr.join("|");
        return {
          ...item[0],
          name: realName,
        };
      });
      realSelectPlaysRef.current = arr;
    } else {
      isSpecialRef.current = false;
    }
  }, [selectPlays]);

  // 确定投注
  const handleBet = async () => {
    if (realSelectPlaysRef.current.length * money > balanceInfo.balance) {
      toast({ msg: "余额不足", type: "tip" });
      return;
    }
    let paramsArr = [];

    if (firstPlay.code === "ZMYL") {
      const arr = [];
      selectPlays.forEach((item) => {
        const idx = arr.findIndex((i) => i.secondCode === item.secondCode);
        if (idx === -1) {
          arr.push({
            ...item,
            oddsArr: [item.code],
          });
        } else {
          arr[idx].oddsArr.push(item.code);
        }
      });
      paramsArr = arr.map((i) => {
        return {
          issueId: currentIssue.rowId,
          playCode1: "LHC",
          playCode2: i.firstCode,
          playCode3: i.secondCode,
          betContent: i.oddsArr,
          unitAmount: money,
          betHall: currentHandicap,
          playCode2Name: i.firstName,
          playCode3Name: i.secondName,
          betOdds:
            playOddInfo[
              `LHC_${i.firstCode}_${i.secondCode || i.firstCode}${
                selectPlays[0].code
              }${selectPlays[0].name === caclCurrentZodiac() ? 0 : ""}`
            ],
        };
      });
    } else {
      let odds;
      // 选择赔率大的
      if (selectPlays.length === 1) {
        odds =
          playOddInfo[
            `LHC_${firstPlay.code}_${secondPlay.code || firstPlay.code}${
              selectPlays[0].code
            }${selectPlays[0].name === caclCurrentZodiac() ? 0 : ""}`
          ];
      } else {
        const odds1 =
          playOddInfo[
            `LHC_${firstPlay.code}_${secondPlay.code || firstPlay.code}${
              selectPlays[0].code
            }${selectPlays[0].name === caclCurrentZodiac() ? 0 : ""}`
          ];
        const odds2 =
          playOddInfo[
            `LHC_${firstPlay.code}_${secondPlay.code || firstPlay.code}${
              selectPlays[1].code
            }${selectPlays[1].name === caclCurrentZodiac() ? 0 : ""}`
          ];
        odds = Number(odds1) > Number(odds2) ? odds1 : odds2;
      }
      paramsArr = [
        {
          issueId: currentIssue.rowId,
          playCode1: "LHC",
          playCode2: firstPlay.code,
          playCode3: secondPlay.code || firstPlay.code,
          betContent: selectPlays.map((i) => i.code),
          unitAmount: money,
          betHall: currentHandicap,
          playCode2Name: firstPlay.name,
          playCode3Name: secondPlay.name || firstPlay.name,
          betOdds: odds,
        },
      ];
    }

    const res = await http(api.bet, paramsArr);
    if (res.code === "C2") {
      onClose();
      onDelete();
      toast({ msg: "投注成功", type: "success" });
      // 投注成功后刷新余额
      getBalanceInfo();
      setTimeout(() => {
        onBetSuccess();
      }, 2000);
    } else {
      toast({ msg: res.message || "投注失败" });
    }
  };
  return (
    <>
      <Modal visible={visible}>
        <div className={styles.modal}>
          <div className={styles.modalTitle}>确认下单</div>
          <div className={styles.modalContent}>
            <div
              className={classNames(styles.betTable, styles.betTableConfirm)}
            >
              <div className={styles.thead}>
                <div className={styles.th}>号码</div>
                <div className={styles.th}>最新赔率</div>
                <div className={styles.th}>金额</div>
              </div>
              <div className={styles.tableContainer}>
                <table className={styles.tbody}>
                  <tbody>
                    {isSpecialRef.current ? (
                      <tr className={styles.tr}>
                        <td className={styles.td}>{`${
                          (selectPlays[0] || {}).secondName
                            ? (selectPlays[0] || {}).secondName + "-"
                            : ""
                        }${selectPlays.map((i) => i.name).join(" | ")}`}</td>
                        <td className={classNames(styles.td)}>
                          {
                            playOddInfo[
                              `LHC_${(selectPlays[0] || {}).firstCode}_${
                                (selectPlays[0] || {}).secondCode ||
                                (selectPlays[0] || {}).firstCode
                              }${(selectPlays[0] || {}).code}`
                            ]
                          }
                        </td>

                        <td className={styles.td}>{Number(money) || ""}</td>
                      </tr>
                    ) : (
                      selectPlays.map((item, index) => {
                        return (
                          <tr className={styles.tr} key={index}>
                            <td className={styles.td}>{`${
                              item.secondName ? item.secondName + "-" : ""
                            }${item.name}`}</td>
                            <td className={styles.td}>
                              {
                                playOddInfo[
                                  `LHC_${item.firstCode}_${
                                    item.secondCode || item.firstCode
                                  }${item.code}${
                                    item.name === caclCurrentZodiac() ? 0 : ""
                                  }`
                                ]
                              }
                            </td>

                            <td className={styles.td}>{Number(money) || ""}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.total}>
              <div className={styles.left}>
                合计：
                <span className={styles.red}>
                  {realSelectPlaysRef.current.length}
                </span>
                笔 共 ¥
                <span className={classNames(styles.red, styles.totalMoney)}>
                  {toFixedString(realSelectPlaysRef.current.length * money)}
                </span>
              </div>
              <div className={styles.right}>
                盘口：<span className={styles.red}>{currentHandicap}</span>盘
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.modalBtnGroup,
              styles.modalBtnGroupConfirm
            )}
          >
            <div
              className={classNames(styles.btn, styles.confirm)}
              onClick={handleBet}
            >
              确定
            </div>
            <div
              onClick={() => {
                onClose();
              }}
              className={classNames(styles.btn, styles.cancel)}
            >
              取消
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BetArea;
