import {
  Navigate,
  Route,
  useNavigate,
  Routes,
  useLocation,
} from "react-router-dom";
import Marquee from "react-fast-marquee";

import Play from "../pages/play";
import Video from "../pages/video";
import Login from "../pages/login";
import Info from "../pages/info";
import GameDesc from "../pages/gameDesc";
import Password from "../pages/password";
import Settlement from "../pages/settlement";
import SettlementDetail from "../pages/settlementDetail";
import Report from "../pages/report";
import ReportDetail from "../pages/reportDetail";
import History from "../pages/history";
import NavHead from "../pages/navHead";
import LeftInfo from "../pages/leftInfo";
import CenterNav from "../pages/centerNav";

import { observer, inject } from "mobx-react";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { classNames } from "../tool";
import api from "../services/api";
import { http } from "../services/fetcher";
import { getLocalStore } from "../tool/localStore";

const myRoutes = [
  {
    path: "/",
    element: <Play />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/play",
    element: <Play />,
  },
  {
    path: "/video",
    element: <Video />,
  },
  {
    path: "/info",
    element: <Info />,
  },
  {
    path: "/settlement",
    element: <Settlement />,
  },
  {
    path: "/settlementDetail",
    element: <SettlementDetail />,
  },
  {
    path: "/reportDetail",
    element: <ReportDetail />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/gameDesc",
    element: <GameDesc />,
  },
  {
    path: "/password",
    element: <Password />,
  },
  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/*",
    element: <Navigate to="/" />,
  },
];

function AnimateRouter({ store }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [noticeList, setnoticeList] = useState([]);
  useEffect(() => {
    if (navigate) {
      window.navigate = navigate;
    }
  }, [navigate]);

  const token = getLocalStore("token");

  const isLoginPage = location.pathname === "/login";
  const isVideoPage = location.pathname === "/video";
  useEffect(() => {
    const getNotice = async () => {
      const res = await http(api.noticeList, {
        page: {
          current: 1,
          size: 999,
        },
      });
      if (res.code === "C2") {
        const list = res.result.records;
        setnoticeList(list);
      }
    };
    if (!isLoginPage && !isVideoPage) {
      getNotice();
    }
    if (!isVideoPage) {
      store.getDicInfo();
    }
  }, []);
  return (
    <div
      className={classNames(
        styles.container,
        isVideoPage ? styles.videoContainer : ""
      )}
    >
      {isLoginPage || isVideoPage ? (
        <Routes>
          {myRoutes.map((item) => (
            <Route path={item.path} element={item.element} key={item.path} />
          ))}
        </Routes>
      ) : (
        <>
          <NavHead />
          <div className={classNames(styles.content, styles.contentPage)}>
            <div className={styles.line} />
            <div className={styles.left}>
              <LeftInfo />
            </div>
            <div className={classNames(styles.center)}>
              <CenterNav />
              <div
                className={classNames(
                  styles.routesContainer,
                  styles.routesContainerPage
                )}
              >
                <Routes>
                  {myRoutes.map((item) => (
                    <Route
                      path={item.path}
                      element={item.element}
                      key={item.path}
                    />
                  ))}
                </Routes>
              </div>
            </div>
          </div>
          {!noticeList.length ? (
            ""
          ) : (
            <div className={styles.marqueeContainer}>
              <Marquee className={styles.marquee}>
                {noticeList.map((item, index) => {
                  return (
                    <span className={styles.marqueeItem} key={index}>
                      {item.noticeTitle}：{item.noticeText}
                    </span>
                  );
                })}
              </Marquee>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default inject("store")(observer(AnimateRouter));
