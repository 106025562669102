import axios from "axios";
import store from "../store";
import {
  closePage,
  getBundleId,
  getParams,
  getTraceId,
  getLocalKey,
  getChannelId,
} from "../tool";
import { getLocalStore, setLocalStore } from "../tool/localStore";
import { v4 as uuidv4 } from "uuid";
axios.defaults.timeout = 60 * 1000;

const getHeaders = (url) => {
  const type =
    url === "/v1/picture/upload"
      ? "application/x-www-form-urlencoded"
      : "application/json";
  return {
    "x-auth-token": getParams("token") || getLocalStore("token", true),
    site: getLocalKey(),
    TraceId: getTraceId(),
    uuid: uuidv4(),
    bundleId: getBundleId(),
    channelId: getChannelId(),
    "content-Type": type,
  };
};

export async function fetcher(resource) {
  store.setLoadingCount(store.loadingCount + 1);
  try {
    const res = await axios.post(resource[0], resource[1] || {}, {
      headers: getHeaders(resource[0]),
    });
    if (res.headers["x-auth-token"]) {
      setLocalStore("token", res.headers["x-auth-token"]);
    }
    if (res.data.code === "C10") {
      closePage();
    }
    return res.data.result;
  } catch (error) {
    return {
      code: "netError",
      message: "网络错误，请重试",
    };
  } finally {
    store.setLoadingCount(store.loadingCount - 1);
  }
}

export async function http(url, args) {
  store.setLoadingCount(store.loadingCount + 1);
  try {
    const res = await axios.post(url, args || {}, { headers: getHeaders(url) });
    if (res.headers["x-auth-token"]) {
      setLocalStore("token", res.headers["x-auth-token"]);
    }
    if (res.data.code === "C10") {
      closePage();
    }
    return res.data;
  } catch (error) {
    return {
      code: "netError",
      message: "网络错误，请重试",
    };
  } finally {
    store.setLoadingCount(store.loadingCount - 1);
  }
}

export async function httpGet(url, args) {
  store.setLoadingCount(store.loadingCount + 1);
  try {
    const res = await axios.get(url, { headers: getHeaders(url) });
    if (res.headers["x-auth-token"]) {
      setLocalStore("token", res.headers["x-auth-token"]);
    }
    if (res.data.code === "C10") {
      closePage();
    }
    return res.data;
  } catch (error) {
    return {
      code: "netError",
      message: "网络错误，请重试",
    };
  } finally {
    store.setLoadingCount(store.loadingCount - 1);
  }
}

export async function noLoadingHttp(url, args) {
  try {
    const res = await axios.post(url, args || {}, { headers: getHeaders(url) });
    if (res.headers["x-auth-token"]) {
      setLocalStore("token", res.headers["x-auth-token"]);
    }
    if (res.data.code === "C10") {
      closePage();
    }
    return res.data;
  } catch (error) {
    return {
      code: "netError",
      message: "网络错误，请重试",
    };
  }
}
