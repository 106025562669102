import {
  classNames,
  SBArr,
  LHCRed,
  LHCBlue,
  LHCGreen,
  calcLHCBetArr,
} from "../../../../../tool";
import NumberCount from "../../../../../components/number";
import styles from "../index.module.scss";
export default function SB(props) {
  const {
    plays,
    selectPlays,
    onBet,
    playOddInfo,
    firstPlay,
    secondPlay,
    money,
  } = props;

  return (
    <div className={classNames(styles.playContainer, styles.SB)}>
      <div className={styles.groupItem}>
        <div className={styles.groupTitle}>
          <div className={styles.typeTitle}>{firstPlay.name}</div>
          <div className={styles.countTitle}>号</div>
          <div className={styles.oddTitle}>赔率</div>
          <div className={styles.amountTitle}>金额</div>
        </div>
        {plays.map((item, index) => {
          const active =
            selectPlays.findIndex((i) => i.code === item.code) !== -1;

          return (
            <div
              key={index}
              className={styles.playItem}
              onClick={() => onBet(item)}
            >
              <div className={styles.name}>{item.name}</div>
              <div className={styles.count}>
                {(secondPlay.code === "SBZ"
                  ? SBArr[index]
                  : calcLHCBetArr(
                      secondPlay.code === "RB"
                        ? LHCRed
                        : secondPlay.code === "BB"
                        ? LHCBlue
                        : LHCGreen,
                      item.name,
                      25
                    )
                ).map((it, idx) => {
                  return (
                    <NumberCount
                      key={idx}
                      number={it}
                      className={styles.number}
                    />
                  );
                })}
              </div>
              <div className={styles.odds}>
                {
                  playOddInfo[
                    `LHC_${firstPlay.code}_${
                      secondPlay.code || firstPlay.code
                    }${item.code}`
                  ]
                }
                {item.code ? (
                  <img
                    className={styles.betImg}
                    src={require(`../../../../../assets/imgs/web/numSel${
                      active ? "" : "Not"
                    }.png`)}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className={styles.amount}>{active ? money : ""}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
