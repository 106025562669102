import Router from "./routes";
import { HashRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { fetcher } from "./services/fetcher";
import { observer, inject } from "mobx-react";

function App({ store }) {
  return (
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: false,
      }}
    >
      <HashRouter>
        <Router />
      </HashRouter>
      <div className={`loading ${store.loadingCount < 1 ? "hide" : ""}`}>
        <img src={require("./assets/imgs/loading.gif")} alt="" />
      </div>
    </SWRConfig>
  );
}

export default inject("store")(observer(App));
