import { Pagination } from "antd";
import { useState } from "react";
import styles from "./index.module.scss";

export default function MyPagination(props) {
  const { total, size, onChange, current } = props;
  const totalPage = Math.ceil(total / size);
  const [page, setPage] = useState("");

  return (
    <div className={styles.pagination}>
      <span className={styles.total}>共 {total} 条记录</span>
      <span className={styles.current}>
        分页：{current}/{totalPage}
      </span>
      <span className={styles.pageBtn} onClick={() => onChange(1)}>
        首页
      </span>

      <Pagination
        pageSizeOptions={[]}
        total={total}
        current={current}
        onChange={onChange}
        pageSize={size}
        showSizeChanger={false}
        itemRender={(_, type, el) => {
          if (type === "prev") {
            return (
              <>
                <span>上一页</span>【
              </>
            );
          }
          if (type === "next") {
            return (
              <>
                】<span>下一页</span>
              </>
            );
          }
          return el;
        }}
      />
      <span className={styles.pageBtn} onClick={() => onChange(totalPage)}>
        尾页
      </span>
      <input
        className={styles.pageInput}
        value={page}
        onChange={(e) => {
          const val = e.target.value;
          setPage(val.replace(/[^0-9]/gi, ""));
        }}
      />

      <div
        className={styles.goBtn}
        onClick={() => {
          const realPage = Number(page);
          if (realPage >= 1 && realPage <= totalPage) {
            onChange(Number(page));
          }
        }}
      >
        跳转
      </div>
    </div>
  );
}
