import styles from "./index.module.scss";
import { getLHCColor, classNames } from "../../tool";

export default function NumberCount(props) {
  const { width, height, number, className, isSeal, fontSize } = props || {};
  const color = getLHCColor(number);
  return (
    <div
      style={{
        width,
        height,
        fontSize,
      }}
      className={classNames(
        styles.number,
        styles[color],
        className,
        isSeal ? styles.isSeal : ""
      )}
    >
      {isSeal ? "?" : number < 10 ? `0${Number(number)}` : number}
    </div>
  );
}
