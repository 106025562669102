import { useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";
import { useCountDown } from "ahooks";
import { toJS } from "mobx";
import Main from "../../components/main";
import NumberCom from "../../components/number";
import Content from "../../components/content";
import api from "../../services/api";
import toast from "../../components/toast";
import { http } from "../../services/fetcher";
import BetModal from "./components/betModal";
import styles from "./index.module.scss";
import { classNames, calcNumZodiac, arrayCombine,calculateSpecialCodeAnimal } from "../../tool";
import { getLocalStore, setLocalStore } from "../../tool/localStore";
import { useSetState } from "ahooks";
import PlayContent from "./components/playContent";

const defaultArr = ["", "", "", "", "", "", ""];
const DEFAULT_ARR = [5, 10, 50, 100, 200];
const MAX = 50000;
function Play({ store }) {
  const {
    balanceInfo,
    playInfo,
    firstPlay,
    secondPlay,
    setSecondPlay,
    secondIndex,
    setSecondIndex,
    currentHandicap,
    dicInfo,
  } = store || {};
  const playInfoJs = toJS(playInfo) || {};
  // 当前期号信息
  const currentIssue = toJS(store.currentIssue) || {};

  const accountNo = getLocalStore("acc")
    ? JSON.parse(getLocalStore("acc")).accountNo
    : "";
  const localArrInfo = getLocalStore("amountInfo")
    ? getLocalStore("amountInfo")
    : {};
  const localArr = localArrInfo[accountNo]
    ? localArrInfo[accountNo]
    : DEFAULT_ARR;

  const [state, setState] = useSetState({
    chipVisible: false,
    chip: localArr,
    cacheChip: localArr,
    cacheInput: localArr,
    chip: localArr,
    cacheChip: localArr,
    cacheInput: localArr,
    secondIndex: 0,
    showBetModal: false, // 展开投注弹窗
    selectPlays: [], // 选中的玩法
    isSeal: false, // 是否封盘
    preIssue: {}, //上期期号信息
    preResult: [], // 上期开奖结果
    arrDesc: [], // 开奖结果生肖
    canBet: false,
    needCout: 0,
    money: "",
  });
  const {
    chipVisible,
    chip,
    cacheChip,
    cacheInput,
    showBetModal,
    selectPlays,
    preIssue,
    isSeal,
    preResult,
    arrDesc,
    canBet,
    needCout,
    money,
  } = state;
  // 赔率
  const playOddInfo = playInfoJs[`playOddInfo${currentHandicap}`] || [];
  const { stime, etime, ctime } = currentIssue || {};
  const { rowId } = preIssue || {};
  const lotteryResult = (preIssue || {}).lotteryResult
    ? (preIssue || {}).lotteryResult
    : defaultArr;
  const showSelectPlaysRes = useRef([]);

  useEffect(() => {
    let needCout = 0;
    if (
      firstPlay.code === "LM" ||
      firstPlay.code === "ZXBZ" ||
      firstPlay.code === "LXLW" ||
      firstPlay.code === "HX"
    ) {
      switch (secondPlay.code) {
        case "EQZ":
        case "EZT":
        case "TC":
        case "EHX":
        case "ELX":
        case "ELW":
          needCout = 2;
          break;
        case "SANQZ":
        case "SANZE":
        case "SANHX":
        case "SANLX":
        case "SANLW":
          needCout = 3;
          break;
        case "SIQZ":
        case "SIHX":
        case "SILX":
        case "SILW":
          needCout = 4;
          break;
        case "WHX":
        case "WLX":
        case "WLW":
        case "ZX05BZ":
          needCout = 5;
          break;
        case "ZX06BZ":
        case "LHX":
          needCout = 6;
          break;
        case "ZX07BZ":
          needCout = 7;
          break;
        case "ZX08BZ":
          needCout = 8;
          break;
        case "ZX09BZ":
          needCout = 9;
          break;
        case "ZX10BZ":
          needCout = 10;
          break;
        case "ZX11BZ":
          needCout = 11;
          break;
        case "ZX12BZ":
          needCout = 12;
          break;
        default:
          break;
      }
    }
    setState({
      needCout,
    });
  }, [firstPlay.code, secondPlay]);

  useEffect(() => {
    if (rowId && lotteryResult.length) {
      const resultArr = JSON.parse(JSON.stringify(lotteryResult));
      resultArr.splice(resultArr.length - 1, 0, "+");
      const arrDesc = [
        calculateSpecialCodeAnimal(resultArr[0], etime), // 第一位生肖
        calculateSpecialCodeAnimal(resultArr[1], etime),
        calculateSpecialCodeAnimal(resultArr[2], etime),
        calculateSpecialCodeAnimal(resultArr[3], etime),
        calculateSpecialCodeAnimal(resultArr[4], etime),
        calculateSpecialCodeAnimal(resultArr[5], etime),
        "+",
        calculateSpecialCodeAnimal(resultArr[7], etime),
      ];
      setState({
        preResult: resultArr || [],
        arrDesc: arrDesc || [],
      });
    }
  }, [rowId]);

  useEffect(() => {
    if (isSeal) {
      toast({ msg: "已封盘" });
    }
  }, [isSeal]);
  // 获取上期信息
  const getPreIssuse = async () => {
    const res = await http(api.historyIssue, {
      page: {
        current: 1,
        size: 1,
      },
    });
    if (res.code === "C2") {
      const issue = ((res.result || {}).records || [])[0] || {};
      setState({
        preIssue: issue,
      });
    }
  };
  useEffect(() => {
    // 当前时间小于结束时间 大于封盘时间
    if (ctime >= stime && ctime <= etime) {
      setState({
        isSeal: true,
      });
    } else {
      setState({
        isSeal: false,
      });
    }
  }, [etime]);
  // 封盘倒计时
  const [time, formattedRes] = useCountDown({
    targetDate: stime,
    onEnd: () => {
      setState({
        isSeal: true,
      });
    },
  });
  // 开奖时间
  const [time2, formattedRes2] = useCountDown({
    targetDate: etime,
    onEnd: () => {
      // 封盘中倒计时结束
      getPreIssuse();
      // 获取当期信息
      store.getcurrentIssue();
      setState({
        isSeal: false,
      });
    },
  });

  useEffect(() => {
    // 获取当期信息
    store.getcurrentIssue();
    // 获取上期信息
    getPreIssuse();
  }, []);

  useEffect(() => {
    if (firstPlay.name) {
      setState({
        selectPlays: [],
        money: "",
        canBet: false,
      });
    }
  }, [firstPlay, secondPlay]);

  /** 选择玩法 */
  const handleSelectPlays = (play, second) => {
    let newSelectPlays = JSON.parse(JSON.stringify(selectPlays));
    // 是否已经选择此玩法
    const idx = newSelectPlays.findIndex(
      (i) =>
        i.code === play.code &&
        i.secondCode === (second ? second.code : secondPlay.code)
    );
    if (idx !== -1) {
      newSelectPlays.splice(idx, 1);
    } else {
      newSelectPlays.unshift({
        ...play,
        firstCode: firstPlay.code,
        firstName: firstPlay.name,
        secondCode: second ? second.code : secondPlay.code,
        secondName: second ? second.name : secondPlay.name,
      });
    }
    let canBet = newSelectPlays.length > 0;
    let needCout = 0;
    if (
      firstPlay.code === "LM" ||
      firstPlay.code === "ZXBZ" ||
      firstPlay.code === "LXLW" ||
      firstPlay.code === "HX"
    ) {
      switch (secondPlay.code) {
        case "EQZ":
        case "EZT":
        case "TC":
        case "EHX":
        case "ELX":
        case "ELW":
          needCout = 2;
          break;
        case "SANQZ":
        case "SANZE":
        case "SANHX":
        case "SANLX":
        case "SANLW":
          needCout = 3;
          break;
        case "SIQZ":
        case "SIHX":
        case "SILX":
        case "SILW":
          needCout = 4;
          break;
        case "WHX":
        case "WLX":
        case "WLW":
        case "ZX05BZ":
          needCout = 5;
          break;
        case "ZX06BZ":
        case "LHX":
          needCout = 6;
          break;
        case "ZX07BZ":
          needCout = 7;
          break;
        case "ZX08BZ":
          needCout = 8;
          break;
        case "ZX09BZ":
          needCout = 9;
          break;
        case "ZX10BZ":
          needCout = 10;
          break;
        case "ZX11BZ":
          needCout = 11;
          break;
        case "ZX12BZ":
          needCout = 12;
          break;
        default:
          break;
      }
      canBet = newSelectPlays.length >= needCout;
      if (canBet) {
        if (newSelectPlays.length > 15) {
          const delIdx = newSelectPlays.findIndex(
            (i) => i.code === play.code && i.secondCode === secondPlay.code
          );
          toast({ msg: "不能超过15个号码" });
          newSelectPlays.splice(delIdx, 1);
        }
        showSelectPlaysRes.current = arrayCombine(newSelectPlays, needCout);
      } else {
        showSelectPlaysRes.current = [];
      }
    } else if (
      firstPlay.code === "ZXBZ" ||
      firstPlay.code === "LXLW" ||
      firstPlay.code === "HX"
    ) {
      if (newSelectPlays.length > needCout) {
        const delIdx = newSelectPlays.findIndex(
          (i) => i.code === play.code && i.secondCode === secondPlay.code
        );
        let text = "号码";
        // 连肖连尾合肖
        if (
          firstPlay.code === "HX" ||
          (firstPlay.code === "LXLW" && firstPlay.name === "连肖")
        ) {
          text = "生肖";
        } else if (firstPlay.name === "连尾") {
          text = "尾数";
        }
        toast({ msg: `不能超过${needCout}个${text}` }); // `不能超过${needCout}个${text}`
        newSelectPlays.splice(delIdx, 1);
      }
      canBet = needCout === newSelectPlays.length;
      if (canBet) {
        if (firstPlay.code === "HX") {
          const reverseArr = JSON.parse(
            JSON.stringify(newSelectPlays)
          ).reverse();
          newSelectPlays = reverseArr;
        }
        showSelectPlaysRes.current = [newSelectPlays];
      } else {
        showSelectPlaysRes.current = [];
      }
    } else if (firstPlay.name === "连肖") {
      showSelectPlaysRes.current = arrayCombine(newSelectPlays, needCout);
    }
    setState({
      selectPlays: newSelectPlays,
      canBet,
      needCout,
      showBetModal: canBet ? showBetModal : false,
    });
  };

  const focusInput = (index) => {
    const arr = JSON.parse(JSON.stringify(cacheInput));
    arr[index] = "";
    setState({
      cacheInput: arr,
    });
  };

  const blurInput = (index) => {
    let arr = JSON.parse(JSON.stringify(cacheChip));
    if (!cacheInput[index]) {
      arr[index] = cacheChip[index];
    }
    arr = arr.map((item) => (item > MAX ? MAX : item));
    const newArr = JSON.parse(JSON.stringify(arr));

    setState({
      cacheInput: newArr,
    });
    // 延时器是为了在确定时能有toast提示
    setTimeout(() => {
      const newArr2 = JSON.parse(JSON.stringify(arr));
      setState({
        cacheChip: newArr2,
      });
    }, 100);
  };

  const changeInput = (e, index) => {
    const val = e.target.value.replace(/[^0-9]/g, "");
    const arr = JSON.parse(JSON.stringify(cacheChip));
    arr[index] = val;
    if (arr[index] > MAX) {
      arr[index] = MAX;
    }
    setState({
      cacheChip: arr,
      cacheInput: arr,
    });
  };

  const handleOk = () => {
    let errText = "";
    const max = MAX;
    const arr = cacheChip.map((item) => {
      let res = item;
      if (item > MAX) {
        errText = `最大设置金额为${max}`;
        res = max;
      } else if (Number(item < 1)) {
        errText = "金额最小为1";
        res = 1;
      }
      return res;
    });
    if (errText) {
      toast({ msg: errText });
      return;
    }
    setState({
      chipVisible: false,
      chip: arr,
    });
    localArrInfo[accountNo] = arr;
    setLocalStore("amountInfo", localArrInfo);
  };

  const onChangeMoney = (e) => {
    const val = e.target.value.replace(/[^0-9]/g, "").substring(0, 6);
    setState({
      money: val,
    });
  };
  const handleClickChip = (item) => {
    setState({
      money: Number(money) + Number(item),
    });
  };

  const renderLotteryResult = () => {
    const notOpen = !(lotteryResult || [])[0] || isSeal;

    return (
      <div className={styles.lotteryResult}>
        <div className={styles.left}>
          <div className={styles.lotteryName}>
            新澳门六合彩 - {firstPlay.name}
          </div>
          <div className={styles.timeInfo}>
            <span className={styles.currentIssue}>{currentIssue.rowId}期</span>
            <div className={styles.sealText}>
              <span className={styles.label}>开奖时间：</span>
              <div className={classNames(styles.timeText, styles.openTime)}>
                {`${formattedRes2.hours < 10
                    ? "0" + formattedRes2.hours
                    : formattedRes2.hours
                  }:${formattedRes2.minutes < 10
                    ? "0" + formattedRes2.minutes
                    : formattedRes2.minutes
                  }:${formattedRes2.seconds < 10
                    ? "0" + formattedRes2.seconds
                    : formattedRes2.seconds
                  }`}
              </div>
            </div>
            <div className={styles.sealText}>
              <span className={styles.label}>距截止下注：</span>
              <div className={classNames(styles.timeText, styles.stopTime)}>
                {isSeal
                  ? "已封盘"
                  : `${formattedRes.hours < 10
                    ? "0" + formattedRes.hours
                    : formattedRes.hours
                  }:${formattedRes.minutes < 10
                    ? "0" + formattedRes.minutes
                    : formattedRes.minutes
                  }:${formattedRes.seconds < 10
                    ? "0" + formattedRes.seconds
                    : formattedRes.seconds
                  }`}
                <div
                  className={styles.liveContainer}
                  onClick={() => {
                    if (isSeal) {
                      if (dicInfo.liveVideoUrl) {
                        window.open(
                          `/#/video?url=${dicInfo.liveVideoUrl}`,
                          "__blank"
                        );
                      } else {
                        toast({ msg: "请刷新页面重试" });
                      }
                    } else {
                      toast({ msg: "未到开奖时间,请耐心等待" });
                    }
                  }}
                >
                  <img src={require("../../assets/imgs/web/iconLive.png")} />
                  开奖直播
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.number}>
            <div className={styles.preIssue}>{preIssue.rowId}开奖</div>
            {preResult.map((item, index) => {
              return item === "+" ? (
                <div className={styles.resultPlus} key={index}>
                  +
                </div>
              ) : (
                <NumberCom
                  isSeal={notOpen}
                  className={styles.resultNumber}
                  number={item}
                  key={index}
                />
              );
            })}
          </div>
          <div className={styles.desc}>
            {arrDesc.map((item, index) => {
              return item === "+" ? (
                <div className={styles.resultPlus} key={index}></div>
              ) : (
                <div className={styles.descItem} key={index}>
                  {isSeal ? "?" : item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  /**二级玩法 */
  const renderSecondTabs = () => {
    const secondTabs = firstPlay.children || [];

    return (
      <div className={styles.secondTabs}>
        {secondTabs.map((item, index) => {
          return (
            <div
              className={classNames(
                styles.tabItem,
                secondIndex === index ? styles.active : ""
              )}
              onClick={() => {
                setSecondIndex(index);
                setSecondPlay(secondTabs[index]);
                setState({
                  selectPlays: [],
                  canBet: false,
                });
              }}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <Main className={styles.main}>
      <Content className={styles.content}>
        {renderLotteryResult()}
        {isSeal ? (
          <div className={styles.sealInner}>
            <img src={require("../../assets/imgs/web/sealBg.png")} />
          </div>
        ) : (
          <>
            <div className={styles.playContent}>
              <div className={styles.rightPlay}>
                {firstPlay.children &&
                  firstPlay.children.length > 1 &&
                  firstPlay.code !== "ZMYL"
                  ? renderSecondTabs()
                  : ""}
                <div
                  className={classNames(
                    styles.playContainer,
                    firstPlay.children &&
                      firstPlay.children.length > 1 &&
                      firstPlay.code !== "ZMYL"
                      ? styles.playContainer2
                      : ""
                  )}
                >
                  <PlayContent
                    money={money}
                    firstPlay={firstPlay}
                    secondPlay={secondPlay}
                    selectPlays={selectPlays}
                    onBet={handleSelectPlays}
                    playOddInfo={playOddInfo}
                  />
                </div>
              </div>
            </div>
            <div className={styles.playBottom}>
              <div
                className={classNames(
                  styles.chipContainer,
                  chipVisible ? styles.show : ""
                )}
              >
                <div className={styles.chipTitle}>快捷金额</div>
                {cacheInput.map((item, idx) => (
                  <div className={styles.inputItem} key={idx}>
                    <input
                      key={idx}
                      type="tel"
                      value={cacheInput[idx]}
                      placeholder="请输入"
                      onFocus={() => focusInput(idx)}
                      onBlur={() => blurInput(idx)}
                      onChange={(e) => changeInput(e, idx)}
                    />
                  </div>
                ))}
                <div className={styles.chipBtn} onClick={handleOk}>
                  保存
                </div>
              </div>
              <div className={styles.betArea}>
                <div className={styles.money}>
                  <span>金额：</span>
                  <input value={money} onChange={onChangeMoney} />
                </div>
                <div className={styles.chipList}>
                  {chip.map((item, index) => {
                    return (
                      <div
                        className={styles.chipItem}
                        key={index}
                        onClick={() => {
                          handleClickChip(item);
                        }}
                      >
                        <span>[</span>
                        {item}
                        <span>]</span>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={styles.chipSet}
                  onClick={() =>
                    setState({
                      chipVisible: !chipVisible,
                    })
                  }
                >
                  金额设置
                </div>
                <div
                  className={styles.betBtn}
                  onClick={() =>
                    setState({
                      money: "",
                      selectPlays: [],
                      showBetModal: false,
                      canBet: false,
                    })
                  }
                >
                  重填
                </div>
                <div
                  className={styles.betBtn}
                  onClick={() => {
                    if (canBet) {
                      if (Number(money)) {
                        setState({
                          showBetModal: true,
                        });
                      } else {
                        toast({ msg: "金额不能为空！" });
                      }
                    } else {
                      if (needCout > 1) {
                        toast({ msg: `最少选取${needCout}码！` });
                      } else {
                        toast({ msg: "请选择下注项目！" });
                      }
                    }
                  }}
                >
                  下注
                </div>
              </div>
            </div>
            <div className={styles.playName}>新澳门六合彩</div>
          </>
        )}
      </Content>

      <BetModal
        visible={showBetModal}
        money={money}
        onClose={() => setState({ showBetModal: false })}
        balanceInfo={store.balanceInfo}
        selectPlays={selectPlays}
        currentIssue={currentIssue}
        showSelectPlays={showSelectPlaysRes.current}
        handleSelectPlays={handleSelectPlays}
        // 投注成功后请求余额
        getBalanceInfo={() => {
          store.getBalanceInfo();
        }}
        onBetSuccess={() => {
          store.addBetSuccessCount();
          setState({
            selectPlays: [],
            showBetModal: false,
            canBet: false,
          });
        }}
        onDelete={() => {
          setState({
            selectPlays: [],
            showBetModal: false,
            canBet: false,
          });
        }}
        secondPlay={secondPlay}
        firstPlay={firstPlay}
        playOddInfo={playOddInfo}
        currentHandicap={currentHandicap}
      />
    </Main>
  );
}

export default inject("store")(observer(Play));
