import ReactDOM from "react-dom/client";
import Modal from "../modal";
import styles from "./index.module.scss";

export default function Toast({ msg, callback, type, autoClose = true }) {
  const div = document.createElement("div");
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  const destroy = () => {
    if (div) {
      root.unmount();
      document.body.removeChild(div);
    }
    if (callback) {
      callback();
    }
  };
  if (autoClose) {
    setTimeout(() => {
      destroy();
    }, 2000);
  }

  root.render(
    <Modal visible={true}>
      <div className={styles.toast}>
        <div className={styles.title}>
          系统讯息
          <img
            className={styles.close}
            onClick={destroy}
            src={require("../../assets/imgs/web/close.png")}
          />
        </div>
        <div className={styles.content}>
          {type ? (
            <img src={require(`../../assets/imgs/web/${type}.png`)} />
          ) : (
            ""
          )}
          <span>{msg}</span>
        </div>
      </div>
    </Modal>
  );
}
