
export const setLocalStore = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(
    {
      time: new Date().getTime(),
      value: value
    }
  ))
}

export const getLocalStore = (key, noTime) => {
  let data = window.localStorage.getItem(key);
  if (data) {
    const jsonData = JSON.parse(data)
    if (noTime) {
      return jsonData.value
    }
    const curTime = (new Date()).getTime();
    if ((curTime - jsonData.time) < (10 * 60 * 1000)) {
      return jsonData.value
    }
  }
  return null
}