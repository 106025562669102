import dayjs from "dayjs";
import toast from "../components/toast";
import api from "../services/api";
import { http, noLoadingHttp } from "../services/fetcher";
import { getLocalStore, setLocalStore } from "./localStore";
import { v4 as uuidv4 } from "uuid";
import { calendar } from "./calcLunarYear";
import _ from "loadsh"

const today = dayjs();

/**
 * 是否是iOS系统
 * @category 检测
 */

export function isiOS() {
  return /ip(hone|ad|od)/i.test(navigator.userAgent);
}
/**
 * 是否是安卓系统
 * @category 检测
 */

export function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}
/**
 * 是否为移动设备
 * @category 检测
 */

export function isMobile() {
  return /Mobi/i.test(navigator.userAgent);
}
export const timeList = [
  {
    text: "全部",
    startTime: today.subtract(365, "day").startOf("day").valueOf(),
    endTime: today.endOf("day").valueOf(),
  },
  {
    text: "今日",
    startTime: today.startOf("day").valueOf(),
    endTime: today.endOf("day").valueOf(),
  },
  {
    text: "昨日",
    startTime: today.subtract(1, "day").startOf("day").valueOf(),
    endTime: today.subtract(1, "day").endOf("day").valueOf(),
  },
  {
    text: "近7日",
    startTime: today.subtract(7, "day").startOf("day").valueOf(),
    endTime: today.endOf("day").valueOf(),
  },
  {
    text: "近15日",
    startTime: today.subtract(15, "day").startOf("day").valueOf(),
    endTime: today.endOf("day").valueOf(),
  },
  {
    text: "近30日",
    startTime: today.subtract(30, "day").startOf("day").valueOf(),
    endTime: today.endOf("day").valueOf(),
  },
];

export const uploadImage = async (file) => {
  const fileType = file.name
    .substring(file.name.lastIndexOf(".") + 1)
    .toLowerCase();
  if (!["png", "jpg", "jpeg", "gif"].includes(fileType)) {
    toast("请上传图片类型文件！");
    return;
  }

  const formData = new FormData(); //初始化时将form Dom对象传入
  formData.append("file", file);
  const data = await http(api.upload, formData);
  if (data.code === "C2") {
    if (data.result) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = data.result;
        image.onload = () => {
          resolve(data.result);
        };
      });
    }
  } else {
    toast(data.message);
  }
};

export const getParams = (key) => {
  let search = window.location.href.replace(/^.*\?/, "").replace(/#.*$/, "");
  let pairs = search.split("&");
  let paramsMap = pairs
    .map((pair) => {
      let [key, value] = pair.split("=");
      return [decodeURIComponent(key), decodeURIComponent(value)];
    })
    .reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
  return paramsMap[key] || "";
};

// 获取浏览器类型
export const getBrowserInfo = () => {
  const u = navigator.userAgent,
    p = navigator.platform,
    isChrome = u.match(/Chrome\/([\d.]+)/) || u.match(/CriOS\/([\d.]+)/); // Chrome
  return {
    //移动终端浏览器版本信息
    winPc: p.indexOf("Win") > -1,
    macPc: p.indexOf("Mac") > -1,
    sysLinux: p === "X11" || p.indexOf("Linux") === 0,
    lieBao: u.indexOf("LieBao") > -1, // 猎豹
    opr: u.indexOf("OPR") > -1, // OPR,
    uc: u.indexOf("UCBrowser") > -1, // UC
    mi: u.indexOf("MiuiBrowser") > -1, // 小米
    iosChrome: u.indexOf("CriOS") > -1,
    chrome: isChrome,
    webview:
      (!isChrome && u.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/)) ||
      u.match(/Android.*(wv|\.0\.0\.0)/),
    iosOpera: u.indexOf("OPiOS") > -1,
    iosUc: u.indexOf("UCBrowser") > -1,
    iosFirefox: u.indexOf("FxiOS") > -1,
    baidu: u.indexOf("baidubrowser") > -1,
    trident: u.indexOf("Trident") > -1, // IE内核
    presto: u.indexOf("Presto") > -1, // opera内核
    webKit: u.indexOf("AppleWebKit") > -1, // 苹果,谷歌内核
    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, // android终端或者uc浏览器
    iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, // 是否为iPhone或者QQHD浏览器
    iPad: u.indexOf("iPad") > -1, // 是否iPad
    webApp: u.indexOf("Safari") > -1, // 是否web应该程序，没有头部与底部
    language: (navigator?.browserLanguage || navigator.language).toLowerCase(),
  };
};

export const closePage = () => {
  setLocalStore("user", null);
  setLocalStore("token", null);
  localStorage.removeItem("login");
  localStorage.removeItem("handicap");
  localStorage.removeItem("select");
  if (!getParams("token")) {
    window.navigate && window.navigate("/login");
    if (!getLocalStore("remember", true)) {
      setLocalStore("accountNo", null);
      setLocalStore("accountPwd", null);
    }
    return;
  }
  const browserInfo = getBrowserInfo();
  const type = getParams("type");
  if (type === "1") {
    //iframe
    window.parent.postMessage("closePageUpay", "*");
  } else if (browserInfo.uc) {
    window.close();
  } else if (
    (browserInfo.android && browserInfo.webview) ||
    (window.android && window.android.returnParameter)
  ) {
    //安卓 app  && window.android.returnParameter
    window.open("https://www.baidu.com/", "_self");
  } else if (
    (browserInfo.ios && browserInfo.webview) ||
    (window.webkit && window.webkit.messageHandlers)
  ) {
    //ios app  window.webkit && window.webkit.messageHandlers
    window.open("https://www.baidu.com", "_self");
    window.open("https://www.baidu.com/", "_self");
  } else {
    window.close();
  }
};

export const saveImg = (imgSrc) => {
  const browserInfo = getBrowserInfo();
  const type = getParams("type");
  if (type === "1") {
    //iframe
    window.parent.postMessage(JSON.stringify({ saveImg: imgSrc }), "*");
  } else if (getStatusHbuildInset()) {
    //Upay应用
    window.plus.gallery.save(
      imgSrc,
      function (event) {
        toast("保存成功");
      },
      function (err) {
        toast("请检查应用后,开启系统权限");
      }
    );
  } else if (type) {
    toast("长按二维码进行保存");
  } else {
    var blob = new Blob([""], { type: "application/octet-stream" });
    var url = URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = imgSrc;
    a.download = imgSrc.replace(/(.*\/)*([^.]+.*)/gi, "$2").split("?")[0];
    var e = document.createEvent("MouseEvents");
    e.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
    URL.revokeObjectURL(url);
  }
};

export const getUser = async (isRefresh) => {
  let user = getLocalStore("user");
  if (user && !isRefresh) {
    return user;
  } else {
    const res = await http(api.memberByAddr, {});
    if (res && res.result) {
      setLocalStore("user", res.result);
      return res.result;
    }
    if (res.code !== "C2") {
      toast(res.message);
    }
  }
};

//rem转换px
export function rem2px(num) {
  return parseFloat(document.documentElement.style.fontSize) * num;
}

export const getPercent = (count, totalCount) => {
  if (totalCount <= 0) {
    return 0;
  }
  let percent = (count / totalCount) * 100;
  if (percent > 100) {
    percent = 100;
  }
  return percent.toFixed();
};

//字符串判断空
export function isEmpty(str) {
  if (!str || str === "undefined" || str === "null") {
    return "-";
  } else {
    return str;
  }
}

//手机震动方法
export const uPayVibration = () => {
  const type = getParams("type");
  if (type === "1") {
    //iframe
    window.parent.postMessage("uPayVibration", "*");
  } else {
    vibration();
  }
};

//判断是否是Hubuild[应用]
export function getStatusHbuildInset() {
  let type = getParams("type");
  if (type === "1") {
    //dafa中
    return false;
  } else {
    if (window?.plus) {
      //判断是否当前Upay应用
      return true;
    } else {
      //其他应用包含H5
      return false;
    }
  }
}

//手机震动 (兼容处理)
export function vibration() {
  const browserInfo = getBrowserInfo();
  try {
    if (browserInfo.ios) {
      // 链接: https://blog.juanertu.com/archives/a402cd10
      const UIImpactFeedbackGenerator = window.plus.ios.importClass(
        "UIImpactFeedbackGenerator"
      );
      const impact = new UIImpactFeedbackGenerator();
      impact.prepare();
      impact.init(1);
      impact.impactOccurred();
    } else {
      window.plus.device.vibrate(80);
    }
  } catch (error) {
    navigator.vibrate =
      navigator?.vibrate ||
      navigator?.webkitVibrate ||
      navigator?.mozVibrate ||
      navigator?.msVibrate;
    if (navigator.vibrate) {
      navigator.vibrate(20);
    }
  }
}

//获取顶部安全距离
export function getStatusbarHeight() {
  if (!getStatusHbuildInset()) return 0;
  const ms = /Html5Plus\/.+\s\(.*(Immersed\/(\d+\.?\d*).*)\)/gi.exec(
    navigator.userAgent
  );
  const immersed = ms && ms.length >= 3;
  let tSafeAreaInsets = window?.plus?.navigator.getStatusbarHeight();
  if (!tSafeAreaInsets && immersed) {
    tSafeAreaInsets = parseFloat(ms[2]);
  }
  return tSafeAreaInsets;
}

export function getLocalKey() {
  switch (process.env.SVC_ENV) {
    case "production":
      return "5566";
    case "staging":
      return "5566";
    case "dev":
      return "5566";
    default:
      return "5566";
  }
}

export const getTraceId = () => {
  let traceId = localStorage.getItem("traceId");
  if (!traceId) {
    const uuid = uuidv4();
    localStorage.setItem("traceId", uuid);
    traceId = uuid;
  }
  return traceId;
};

export const getBundleId = () => {
  if (!isMobile()) {
    return "PC";
  } else {
    if (isAndroid() || isiOS()) {
      return process.env.LOCAL_VERSION;
    } else {
      return "H5";
    }
  }
};

export const ChannelId = {
  PC: "A",
  iOS: "B",
  Android: "C",
  H5: "D",
  other: "E",
};

//动态获取设备内容
export function getChannelId() {
  const browserInfo = getBrowserInfo();
  if (browserInfo.webview) {
    if (browserInfo.ios) {
      return ChannelId.iOS;
    } else if (browserInfo.android) {
      return ChannelId.Android;
    } else {
      return ChannelId.other; //其他
    }
  } else {
    return ChannelId.H5;
  }
}

let dfadaliveTimer = null;
let isKeep = true;
export const pingDfadalive = async () => {
  await noLoadingHttp(api.dfadalive, {});
  clearTimeout(dfadaliveTimer);
  if (isKeep) {
    dfadaliveTimer = setTimeout(() => {
      pingDfadalive();
    }, 5000);
  }
};

export const stopPingDfadalive = () => {
  clearTimeout(dfadaliveTimer);
  isKeep = false;
};

export const startPingDfadalive = () => {
  isKeep = true;
};

export const parseDate = (time) => {
  const seconds = parseInt(time / 1000);
  let minutes = parseInt(seconds / 60);
  let remaind = seconds % 60;
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (remaind < 10) {
    remaind = "0" + remaind;
  }
  return minutes + ":" + remaind;
};

export const imgToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = () => {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      let dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
      canvas = null;
    };
  });
};

export const base64ToFile = (data, name) => {
  let base = window.atob(data.substring(data.indexOf(",") + 1));
  let length = base.length;
  let url = new Uint8Array(length);
  while (length--) {
    url[length] = base.charCodeAt(length);
  }
  let file = new File([url], `upay_avatar_${name}.png`, {
    type: "image/png",
  });
  return file;
};

export function classNames(...args) {
  const classes = [];

  for (let i = 0; i < args.length; i++) {
    const arg = args[i];
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === "string" || argType === "number") {
      classes.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      const inner = classNames(...arg);
      if (inner) {
        classes.push(inner);
      }
    } else if (argType === "object") {
      for (const key in arg) {
        // eslint-disable-next-line no-prototype-builtins
        if (arg.hasOwnProperty(key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  return classes.join(" ");
}
export function isJudge(str) {
  return (a, b) => {
    return str ? a : b;
  };
}
/**计算当前期号 */
export function calculateIssue(str, subCount = 4) {
  const issueStr = (str || "").toString().substring(subCount);
  return issueStr.replace(/\b(0+)/gi, "") || "";
}
/**六合彩相关 */
/**计算六合彩生肖 */
const lastMouseYear = 2020;
const maxNum = 49;
export const initZodiacArr = [
  "鼠",
  "牛",
  "虎",
  "兔",
  "龙",
  "蛇",
  "马",
  "羊",
  "猴",
  "鸡",
  "狗",
  "猪",
];

/**计算今年生肖 */
export function caclCurrentZodiac() {
  const oldZodiacArr = JSON.parse(JSON.stringify(initZodiacArr));
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  const chineseYear = calendar.solar2lunar(year, month, date) || {};
  const nowYear = chineseYear.lYear || 2023;

  const idx = nowYear % lastMouseYear; // 今年生肖的位置
  return oldZodiacArr[idx];
}
export function calcZodiac() {
  const zodiac = caclCurrentZodiac();
  const idx = initZodiacArr.findIndex((i) => i === zodiac);
  // 最大号码为49，最多5个号码
  const multiplyArr = Array.from({ length: 5 }, (v, k) => k);
  const resArr = Array.from({ length: 12 }, (v, k) => []);
  initZodiacArr.forEach((item, i) => {
    let prevIdx = idx - i;
    prevIdx = prevIdx < 0 ? prevIdx + initZodiacArr.length : prevIdx; // 上一个要计算生肖的位置
    multiplyArr.forEach((itm) => {
      const num = initZodiacArr.length * itm + prevIdx + 1;
      if (num <= maxNum) {
        resArr[i].push(num);
      }
    });
  });
  return resArr;
}
export const zodiacBetArr = calcZodiac();
/**计算当前数字所属的生肖 */
export function calcNumZodiac(numStr) {
  const num = Number(numStr);
  const idx = zodiacBetArr.findIndex(
    (item) => item.findIndex((i) => i === num) !== -1
  );
  return initZodiacArr[idx];
}

/** 计算尾数 */
export function calcMantissa(maintissa, maxNum) {
  const str = maintissa + "";
  const arr = [];
  Array.from({ length: maxNum }, (v, k) => k + 1).forEach((item) => {
    if (item % 10 === Number(str)) {
      arr.push(item);
    }
  });
  return arr;
}

/** 计算头数 */
export function calcHead(head, maxNum) {
  const str = head + "";
  const arr = [];
  Array.from({ length: maxNum }, (v, k) => k + 1).forEach((item) => {
    if (item < (Number(str) + 1) * 10 && item >= Number(str) * 10) {
      arr.push(item < 10 ? "0" + item : item);
    }
  });
  return arr;
}
export const LHCRed = [
  1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46,
];
export const LHCBlue = [
  3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48,
];
export const LHCGreen = [
  5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49,
];

export const SBArr = [LHCRed, LHCBlue, LHCGreen];

const JiaAnimal = ["牛", "马", "羊", "鸡", "狗", "猪"];
export function getLHCAnimal(num) {
  const zodiac = calculateSpecialCodeAnimal(num);
  return JiaAnimal.indexOf(zodiac) === -1 ? "野禽" : "家禽";
}
export function getLHCColor(numStr) {
  const num = Number(numStr);
  const isRed = LHCRed.indexOf(num) !== -1;
  const isBlue = LHCBlue.indexOf(num) !== -1;
  return isRed ? "red" : isBlue ? "blue" : "green";
}

/**数组排序  默认升 */
export function sortArr(arr, isDown, key, isNum) {
  arr = arr.sort(function (a, b) {
    const valA = isNum ? +a : a;
    const valB = isNum ? +b : b;
    return isDown
      ? key
        ? valB[key] - valA[key]
        : valB - valA
      : key
        ? valA[key] - valB[key]
        : valA - valB;
  });
  return arr;
}

/**
 *
 * @param {*} arr 数组
 * @param {*} type 投注类型 大 小 单 双 大双 小双 大单 小单
 * @param {*} bigCount 临界值 大
 */
export function calcLHCBetArr(arr, type, bigCount) {
  const resArr = [];
  // eslint-disable-next-line array-callback-return
  arr.map((i) => {
    switch (type) {
      case "红大":
      case "蓝大":
      case "绿大":
      case "Red Big":
      case "Blue Big":
      case "Green Big":
        if (i >= bigCount) {
          resArr.push(i);
        }
        break;
      case "红小":
      case "蓝小":
      case "绿小":
      case "Red Small":
      case "Blue Small":
      case "Green Small":
        if (i < bigCount) {
          resArr.push(i);
        }
        break;
      case "红单":
      case "蓝单":
      case "绿单":
      case "Red Odd":
      case "Blue Odd":
      case "Green Odd":
        if (i % 2 === 1) {
          resArr.push(i);
        }
        break;
      case "红双":
      case "蓝双":
      case "绿双":
      case "Red Even":
      case "Blue Even":
      case "Green Even":
        if (i % 2 === 0) {
          resArr.push(i);
        }
        break;
      case "红大双":
      case "蓝大双":
      case "绿大双":
      case "Red Big Even":
      case "Blue Big Even":
      case "Green Big Even":
        if (i >= bigCount && i % 2 === 0) {
          resArr.push(i);
        }
        break;
      case "红小双":
      case "蓝小双":
      case "绿小双":
      case "Red Small Even":
      case "Blue Small Even":
      case "Green Small Even":
        if (i < bigCount && i % 2 === 0) {
          resArr.push(i);
        }
        break;
      case "红大单":
      case "蓝大单":
      case "绿大单":
      case "Red Big Odd":
      case "Blue Big Odd":
      case "Green Big Odd":
        if (i >= bigCount && i % 2 === 1) {
          resArr.push(i);
        }
        break;
      case "红小单":
      case "蓝小单":
      case "绿小单":
      case "Red Small Odd":
      case "Blue Small Odd":
      case "Green Small Odd":
        if (i < bigCount && i % 2 === 1) {
          resArr.push(i);
        }
        break;
      default:
        break;
    }
  });
  return resArr;
}

/**
 * 获得从m中取n的所有组合
 */
function getFlagArrs(m, n = 1) {
  if (n < 1 || m < n) return [];

  // 先生成一个长度为 m 字符串，开头为 n 个 1， 例如“11100”
  let str = "1".repeat(n) + "0".repeat(m - n);
  let pos;
  // 1
  const resultArrs = [Array.from(str, (x) => Number(x))];
  const keyStr = "10";

  while (str.indexOf(keyStr) > -1) {
    pos = str.indexOf(keyStr);
    // 2
    str = str.replace(keyStr, "01");
    // 3
    str =
      Array.from(str.slice(0, pos))
        .sort((a, b) => b - a)
        .join("") + str.slice(pos);
    // 4
    resultArrs.push(Array.from(str, (x) => Number(x)));
  }
  return resultArrs;
}

/**
 * 获得指定数组的所有组合
 */
export function arrayCombine(arr, cot) {
  const targetArr = arr || [];
  const count = cot || [];
  if (!Array.isArray(targetArr)) return [];
  const resultArrs = [];
  // 所有组合的 01 排列
  const flagArrs = getFlagArrs(targetArr.length, count);
  while (flagArrs.length) {
    const flagArr = flagArrs.shift();
    resultArrs.push(targetArr.filter((_, idx) => flagArr[idx]));
  }
  return resultArrs;
}
/**设置起始时间 */
export function getTime(days, endDays) {
  const start = dayjs().subtract(days, "day").startOf("day").valueOf();
  const end = dayjs().subtract(endDays, "day").endOf("day").valueOf();
  return {
    start: start ? start : "",
    end: end ? end : "",
  };
}

/**
 * 设置起始时间
 * 0 今天 1 昨天 2 本周 3 上周
 */
export function getTime2(type) {
  const nowDate = new Date();
  // 当前是周几 0-6
  const nowWeek = nowDate.getDay();
  const minusDay = nowWeek != 0 ? nowWeek : 7;
  let start;
  let end;
  switch (type) {
    case 0:
      start = dayjs().startOf("day").valueOf();
      end = dayjs().endOf("day").valueOf();
      break;
    case 1:
      start = dayjs().subtract(1, "day").startOf("day").valueOf();
      end = dayjs().subtract(1, "day").endOf("day").valueOf();
      break;
    case 2:
      start = dayjs()
        .subtract(minusDay - 1, "day")
        .startOf("day")
        .valueOf();
      end = dayjs().endOf("day").valueOf();
      break;
    case 3:
      start = dayjs()
        .subtract(minusDay + 6, "day")
        .startOf("day")
        .valueOf();
      end = dayjs().subtract(minusDay, "day").endOf("day").valueOf();
      break;
  }
  return {
    start: start ? start : "",
    end: end ? end : "",
  };
}
/**求和值 */
export function getTotal(arr) {
  return arr && arr.length > 0
    ? arr.reduce((a, b) => Number(a) + Number(b))
    : "";
}

/** 计算当前数值的大小 */
export function caclSize(num, max, isTotal) {
  return Number(num) === 49 && !isTotal ? "和" : max / 2 < num ? "大" : "小";
}

/** 计算当前数值的单双 */
export function caclDanShuang(num, isTotal) {
  return Number(num) === 49 && !isTotal ? "和" : num % 2 === 0 ? "双" : "单";
}

/**保留两位位有效数字 */
export function toFixedString(num) {
  const tempVal = parseFloat(((num || 0) * 1).toString()).toFixed(3);
  const realVal = tempVal.substring(0, tempVal.length - 1);
  return realVal;
}
/**反转数组 */
export function reverseArr(arr) {
  return arr.map((_, index) => arr[arr.length - 1 - index]);
}

/*
获取当前url的参数转成json
*/
export function getUrlParamsToJson() {
  try {
    let currUrl = window.location.href;
    let pars = currUrl.match(/\?([^#]+)/)[1];
    var obj = {},
      arr = pars.split("&");
    for (var i = 0; i < arr.length; i++) {
      var subArr = arr[i].split("=");
      obj[decodeURIComponent(subArr[0])] = decodeURIComponent(subArr[1]);
    }

    return obj;
  } catch (err) {
    return {};
  }
}

/**去重 */
export function unique(arrPa) {
  const arr = JSON.parse(JSON.stringify(arrPa));
  for (var i = 0; i < arr.length; i++) {
    for (var j = i + 1; j < arr.length; j++) {
      if (arr[i] == arr[j]) {
        //第一个等同于第二个，splice方法删除第二个
        arr.splice(j, 1);
        j--;
      }
    }
  }
  return arr;
}

export function groupArr(arrPa, groupCount = 10) {
  const arr = [];
  const lines = Math.ceil(arrPa.length / groupCount);
  Array.from({ length: lines }, (v, k) => k).forEach((i) => {
    let newArr = arrPa.slice(i * groupCount, i * groupCount + groupCount);
    if (newArr.length < groupCount) {
      newArr = newArr.concat(
        Array.from({ length: groupCount - newArr.length }, (v, k) => {
          return {
            code: "",
            name: "",
          };
        })
      );
    }
    arr.push(newArr);
  });
  return arr;
}

/**
 * 六合彩生肖 计算
 *  @param specialCode{number}      特码
 *  @param date{moment}      订单创建时间
 */
export function calculateSpecialCodeAnimal(specialCode, date = "") {
  let code = Number(specialCode)
  const currentDate = date ? new Date(date) : new Date();
  const lunarDate = calendar.solar2lunar(currentDate.getFullYear(), currentDate?.getMonth() + 1, currentDate.getDate())
  // 根据年份计算干支纪年
  const ganZhiYear = calculateGanZhiYear(lunarDate?.lYear);
  // 根据干支纪年和特码号码确定生肖
  const animal = calculateAnimal(ganZhiYear, Number(code));
  return animal;
}

// 根据年份计算干支纪年
function calculateGanZhiYear(year) {
  // 请根据实际情况编写代码来计算干支纪年
  // 这里只是一个示例，使用简单的计算方法

  const startYear = 1900;
  const ganZhiAnimals = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];

  const ganZhiIndex = (year - startYear) % 12;
  const ganZhiYear = ganZhiAnimals[ganZhiIndex];

  return ganZhiYear;
}

// 根据干支纪年和特码号码确定生肖
function calculateAnimal(ganZhiYear, specialCode) {
  // 请根据实际情况编写代码来确定特码对应的生肖
  // 这里只是一个示例，使用简单的计算方法
  let ganZhiAnimals = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];
  const animals = _.concat(ganZhiAnimals, ganZhiAnimals.splice(0, ganZhiAnimals.indexOf(ganZhiYear)))

  // 根据特码号码确定生肖的索引
  const specialCodeIndex = (specialCode - 1) % 12;
  // 根据干支纪年和特码索引确定生肖
  const animal = animals[(12 - (animals.indexOf(ganZhiYear) + specialCodeIndex) % 12) % 12];

  return animal;
}

