import { useEffect, useState } from "react";
import { useSetState } from "ahooks";
import { Select } from "antd";
import md5 from "js-md5";
import Main from "../../components/main";
import Content from "../../components/content";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { classNames, toFixedString } from "../../tool";
import { observer, inject } from "mobx-react";
import api from "../../services/api";
import { http, httpGet } from "../../services/fetcher";
import toast from "../../components/toast";

function Info({ store }) {
  const { balanceInfo } = store;
  const navigate = useNavigate();

  const DEFAULT_IP = ["", ""];
  const localIpArr = DEFAULT_IP;
  // 返水
  const tableColumns = [
    {
      title: "交易类型",
      dataIndex: "name",
      width: "30rem",
    },
    {
      title: "A盘",
      dataIndex: "a",
      width: "17.5rem",
    },
    {
      title: "B盘",
      dataIndex: "b",
      width: "17.5rem",
    },
    {
      title: "C盘",
      dataIndex: "c",
      width: "17.5rem",
    },
    {
      title: "D盘",
      dataIndex: "d",
      width: "17.5rem",
    },
  ];

  const [state, setState] = useSetState({
    ip: localIpArr,
    cacheIp: localIpArr,
    cacheInputIp: localIpArr,
  });
  const { cacheChip, cacheIp, cacheInputIp } = state;

  const [bonusData, setBonusData] = useState({
    playInfo: [],
    playOddInfoA: [],
    playOddInfoB: [],
    playOddInfoC: [],
    playOddInfoD: [],
  });
  const { playInfo } = bonusData;

  useEffect(() => {
    if (balanceInfo.accessIps) {
      const ips = balanceInfo.accessIps.split(",");
      setState({
        ip: ips,
        cacheIp: ips,
        cacheInputIp: ips,
      });
    }
  }, [balanceInfo]);

  useEffect(() => {
    const getBonus = async () => {
      const res = await httpGet(api.bonus);
      if (res.code === "C2") {
        const data = res.result;
        const playInfoRes =
          (JSON.parse(data.playInfo || "{}").LHC || {}).playInfo || [];
        const { playOddInfoA, playOddInfoB, playOddInfoC, playOddInfoD } = data;
        // 重新设置下各个玩法的返水数据
        const arr = [];
        playInfoRes.map((item) => {
          if (item.children) {
            item.children.map((it) => {
              arr.push({
                ...it,
                name: it.name,
                a: playOddInfoA[`LHC_${item.code}_${it.code}`],
                b: playOddInfoB[`LHC_${item.code}_${it.code}`],
                c: playOddInfoC[`LHC_${item.code}_${it.code}`],
                d: playOddInfoD[`LHC_${item.code}_${it.code}`],
              });
            });
          } else {
            arr.push({
              ...item,
              a: playOddInfoA[`LHC_${item.code}_${item.code}`],
              b: playOddInfoB[`LHC_${item.code}_${item.code}`],
              c: playOddInfoC[`LHC_${item.code}_${item.code}`],
              d: playOddInfoD[`LHC_${item.code}_${item.code}`],
            });
          }
        });
        data.playInfo = arr;
        setBonusData(data);
      }
    };
    getBonus();
  }, []);

  // 个人资料
  const renderAccountInfo = () => {
    const betHallArr = (balanceInfo.betHall || "").split("_");
    return (
      <div className={styles.contentItem}>
        <div className={styles.itemTitle}>信用资料</div>
        <div className={styles.accountItem}>
          <div className={styles.label}>会员资料</div>
          <div className={styles.value}>{balanceInfo.accountNo}</div>
        </div>
        <div className={styles.accountItem}>
          <div className={styles.label}>盘口</div>
          <div className={styles.value}>
            {betHallArr.map((item) => (
              <span className={styles.hallVal} key={item}>
                {item}
              </span>
            ))}
            盘
          </div>
        </div>
        <div className={styles.accountItem}>
          <div className={styles.label}>信用额度</div>
          <div className={styles.value}>
            {toFixedString(balanceInfo.balance)}
          </div>
        </div>
        <div className={styles.accountItem}>
          <div className={styles.label}>可用金额</div>
          <div className={styles.value}>
            {toFixedString(balanceInfo.balance)}
          </div>
        </div>
        <div className={styles.accountItem}>
          <div className={styles.label}>绑定IP</div>
          <div className={classNames(styles.value, styles.ipContainer)}>
            {renderIP()}
          </div>
        </div>
      </div>
    );
  };

  const focusIpInput = (index) => {
    // const arr = JSON.parse(JSON.stringify(cacheInputIp));
    // arr[index] = "";
    // setState({
    //   cacheInputIp: arr,
    // });
  };

  const blurIpInput = (index) => {
    let arr = JSON.parse(JSON.stringify(cacheIp));
    if (!cacheInputIp[index]) {
      arr[index] = cacheIp[index];
    }
    const newArr = JSON.parse(JSON.stringify(arr));

    setState({
      cacheInputIp: newArr,
      cacheIp: newArr,
    });
    // 延时器是为了在确定时能有toast提示
  };

  const changeIpInput = (e, index) => {
    const val = e.target.value.replace(/[^\d.]/g, "");
    console.log(val);
    const arr = JSON.parse(JSON.stringify(cacheIp));
    arr[index] = val;
    setState({
      cacheIp: arr,
      cacheInputIp: arr,
    });
  };

  const handleIpOk = async () => {
    let errText = "";
    const ipReg =
      /((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)/;
    cacheInputIp.forEach((i) => {
      if (i && !ipReg.test(i)) {
        errText = "请输入正确的IP";
      }
    });

    if (errText) {
      toast({ msg: errText });
      return;
    }
    const ips = setState({
      ip: cacheChip,
    });
    const res = await http(api.bindAccessIp, {
      accountNo: balanceInfo.accountNo,
      accessIps: cacheIp.join(","),
    });
    if (res.code === "C2") {
      toast({ msg: "绑定成功" });
    } else {
      toast({ msg: res.message || "绑定失败" });
    }
  };

  // 绑定IP
  const renderIP = () => {
    return (
      <>
        {DEFAULT_IP.map((item, idx) => {
          return (
            <>
              <span className={styles.ipLabel}>IP{idx + 1}:</span>
              <input
                key={idx}
                type="tel"
                value={cacheInputIp[idx]}
                placeholder=""
                onFocus={() => focusIpInput(idx)}
                onBlur={() => blurIpInput(idx)}
                onChange={(e) => changeIpInput(e, idx)}
              />
            </>
          );
        })}
        <div className={styles.bindBtn} onClick={handleIpOk}>
          绑定
        </div>
      </>
    );
  };
  // 返水

  const renderBonus = () => {
    return (
      <>
        <div className={styles.bonusTitle}>
          <Select
            className={styles.select}
            value={"1"}
            style={{ width: 150 }}
            onChange={(val) => {}}
            options={[
              {
                label: "新澳门六合彩",
                value: "1",
              },
            ]}
          />
        </div>

        <div className={styles.table}>
          <div className={styles.thead}>
            {tableColumns.map((item, index) => {
              return (
                <div
                  className={styles.th}
                  key={index}
                  style={{ width: item.width }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <div className={styles.tbody}>
            {playInfo.map((item, index) => {
              return (
                <div className={styles.tr} key={index}>
                  {tableColumns.map((it, idx) => {
                    return (
                      <div
                        className={styles.td}
                        key={idx}
                        style={{ width: it.width }}
                      >
                        {item[it.dataIndex]}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  return (
    <Main className={styles.container}>
      <Content className={styles.mainBox}>
        <div className={styles.contentInner}>
          {renderAccountInfo()}
          {renderBonus()}
        </div>
      </Content>
    </Main>
  );
}

export default inject("store")(observer(Info));
