import { useEffect } from "react";
import { useSetState } from "ahooks";
import { Select } from "antd";
import Main from "../../components/main";
import Modal from "../../components/modal";
import Content from "../../components/content";
import { observer, inject } from "mobx-react";
import styles from "./index.module.scss";
import { http } from "../../services/fetcher";
import api from "../../services/api";
import Number from "../../components/number";
import MyPagination from "../../components/pagination";
import {
  calculateSpecialCodeAnimal,
  calcNumZodiac,
  getTotal,
  caclDanShuang,
  caclSize,
  classNames,
  unique,
} from "../../tool";
import dayjs from "dayjs";

function History({ store }) {
  const { dicInfo } = store;
  const [state, setState] = useSetState({
    data: [],
    current: 1,
    refreshCount: 1,
    tableIndex: 0,
    total: 0,
    liveUrl: "",
    liveVisible: false,
  });
  const { data, current, refreshCount, total, liveUrl, liveVisible } = state;
  const tableColumns = [
    {
      title: "期数",
      dataIndex: "rowId",
    },
    {
      title: "开奖时间",
      dataIndex: "etime",
    },
    {
      title: "开出号码",
      dataIndex: "lotteryResult",
    },
    {
      title: "往期直播",
      dataIndex: "lotteryResult",
    },
    {
      title: "肖量",
      dataIndex: "lotteryResult",
    },
    {
      title: "尾量",
      dataIndex: "lotteryResult",
    },
    {
      title: "单双",
      dataIndex: "lotteryResult",
    },
    {
      title: "大小",
      dataIndex: "lotteryResult",
    },
    {
      title: "单双",
      dataIndex: "lotteryResult",
    },
    {
      title: "总合",
      dataIndex: "lotteryResult",
    },
    {
      title: "单双",
      dataIndex: "lotteryResult",
    },
    {
      title: "大小",
      dataIndex: "lotteryResult",
    },
  ];
  // 获取历史开奖信息
  const queryData = async (params) => {
    const res = await http(api.historyIssue, params);
    if (res.code === "C2") {
      const records = res.result.records;

      let arr = records;
      arr = arr
        .filter((i) => i.lotteryResult)
        .map((item) => {
          const resultArr = JSON.parse(JSON.stringify(item.lotteryResult));
          resultArr.splice(resultArr.length - 1, 0, "+");
          const arrDesc = [
            calculateSpecialCodeAnimal(resultArr[0], item?.etime), // 第一位生肖
            calculateSpecialCodeAnimal(resultArr[1], item?.etime),
            calculateSpecialCodeAnimal(resultArr[2], item?.etime),
            calculateSpecialCodeAnimal(resultArr[3], item?.etime),
            calculateSpecialCodeAnimal(resultArr[4], item?.etime),
            calculateSpecialCodeAnimal(resultArr[5], item?.etime),
            calculateSpecialCodeAnimal(resultArr[7], item?.etime),
          ];
          arrDesc.splice(arrDesc.length - 1, 0, "+");
          const zoadicCount = unique(arrDesc).length - 1;
          const weiArr = [
            resultArr[0] % 10, // 第一位尾数
            resultArr[1] % 10,
            resultArr[2] % 10,
            resultArr[3] % 10,
            resultArr[4] % 10,
            resultArr[5] % 10,
            resultArr[7] % 10,
          ];
          const weiCount = unique(weiArr).length;
          const total = getTotal(item.lotteryResult);
          const lastArr = resultArr[7].split("");
          const lastTotal = getTotal(lastArr);
          const lastTotalDanShuang = caclDanShuang(lastTotal, true);
          const size = caclSize(resultArr[7], 49, true);
          const danShuang = caclDanShuang(resultArr[7], true);
          const totalSize = caclSize(total, 349, true);
          const totalDanShuang = caclDanShuang(total, true);

          return {
            ...item,
            resultArr,
            arrDesc,
            zoadicCount,
            weiCount,
            lastTotalDanShuang,
            total,
            size,
            danShuang,
            totalSize,
            totalDanShuang,
          };
        });

      setState({ data: arr, total: res.result.total });
    }
  };
  useEffect(() => {
    // 默认请求历史开奖
    queryData({
      page: {
        current,
        size: 20,
      },
    });
  }, [current, refreshCount]);

  const renderItem = (item, index) => {
    const {
      resultArr,
      rowId,
      etime,
      arrDesc,
      zoadicCount,
      weiCount,
      size,
      danShuang,
      lastTotalDanShuang,
      total,
      totalSize,
      totalDanShuang,
    } = item;

    return (
      <div className={styles.tr} key={index}>
        <div className={styles.td}>{rowId}</div>
        <div className={styles.td}>
          {dayjs(etime).format("YYYY-MM-DD HH:mm:ss")}
        </div>
        <div className={styles.td}>
          {resultArr.map((it, idx) => {
            return it === "+" ? (
              <div className={styles.resultPlus} key={index + idx}>
                +
              </div>
            ) : (
              <div className={styles.descItem} key={idx + index}>
                <Number
                  heig
                  className={styles.resultNumber}
                  number={it}
                  key={index + idx}
                />
                <div className={styles.desc}>{arrDesc[idx]}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.td}>
          <div
            className={styles.liveContainer}
            onClick={() => {
              if (dicInfo.hisVideoUrl) {
                setState({
                  liveUrl: `${dicInfo.hisVideoUrl}/${rowId}.mp4`,
                  liveVisible: true,
                });
                // window.open(, "__blank");
              }
            }}
          >
            <img src={require("../../assets/imgs/web/liveHistory.png")} />
            <span className={styles.liveText}>回放</span>
          </div>
        </div>
        <div className={styles.td}>{zoadicCount}</div>
        <div className={styles.td}>{weiCount}</div>
        <div
          className={classNames(
            styles.td,
            danShuang === "单" ? styles.blue : styles.yellow
          )}
        >
          {danShuang}
        </div>
        <div
          className={classNames(
            styles.td,
            size === "小" ? styles.blue : styles.yellow
          )}
        >
          {size}
        </div>
        <div
          className={classNames(
            styles.td,
            lastTotalDanShuang === "单" ? styles.blue : styles.yellow
          )}
        >
          {lastTotalDanShuang}
        </div>
        <div className={styles.td}>{total}</div>
        <div
          className={classNames(
            styles.td,
            totalDanShuang === "单" ? styles.blue : styles.yellow
          )}
        >
          {totalDanShuang}
        </div>
        <div
          className={classNames(
            styles.td,
            totalSize === "小" ? styles.blue : styles.yellow
          )}
        >
          {totalSize}
        </div>
      </div>
    );
  };
  return (
    <Main>
      <Content className={styles.content}>
        <div className={styles.contentInner}>
          <div className={styles.contentTitle}>
            <Select
              className={styles.select}
              value={"1"}
              style={{ width: 150 }}
              onChange={(val) => { }}
              options={[
                {
                  label: "新澳门六合彩",
                  value: "1",
                },
              ]}
            />
          </div>
          <div className={classNames(styles.thead, styles.thead1)}>
            <div className={styles.th}>新澳门六合彩</div>
            <div className={styles.th}>特码</div>
            <div className={styles.th}>特合</div>
            <div className={styles.th}>总数</div>
          </div>
          <div className={classNames(styles.thead, styles.thead2)}>
            {tableColumns.map((item, index) => {
              return (
                <div
                  className={styles.th}
                  key={index}
                  style={{ width: item.width }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>

          <div className={styles.tbody}>
            {data.map((item, index) => {
              return renderItem(item, index);
            })}
          </div>
          <div className={styles.pagination}>
            <MyPagination
              current={current}
              size={20}
              total={total}
              onChange={(val) => setState({ current: val })}
            />
          </div>
        </div>
        <Modal visible={liveVisible}>
          <div className={styles.video}>
            <div
              className={styles.close}
              onClick={() =>
                setState({
                  liveVisible: false,
                  liveUrl: "",
                })
              }
            >
              <img src={require("../../assets/imgs/web/close.png")} />
            </div>
            <video
              style={{ width: 600 }}
              src={liveUrl}
              controls
              autoPlay
            ></video>
          </div>
        </Modal>
      </Content>
    </Main>
  );
}

export default inject("store")(observer(History));
